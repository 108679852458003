.container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 562px;
  margin: auto;
  z-index: 1000;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .label {
      color: #000;
      font-weight: 600;
      line-height: normal;
    }

    .group {
      width: 100%;
      display: flex;
      flex-direction: column;
      // align-items: ;
      // justify-content: space-between;
      // align-items: center;
      gap: 20px;

      .select {
        max-width: 400px;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title {
    color: #02285e;
    font-size: 32px;
    font-weight: 700;
    line-height: 20px;
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    display: flex;
    min-width: 95px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
}
