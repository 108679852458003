.container {
  display: flex;
  flex-direction: column;
  // gap: 1rem;

  height: fit-content;
  border-radius: 10px;
  border-top-left-radius: 0px !important;

  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  .heading {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #eb2701;
  }

  .form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // gap: 1rem;
    gap: 5px;

    .campaign-obj-date-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;

      .date-input {
        width: 100%;
        height: 52px !important;
      }

      .objective {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    div {
      width: 100%;
    }

    .footer {
      display: flex;
      flex-direction: row-reverse;
      gap: 10px;
      button {
        min-width: 90px !important;
      }
    }
  }
}

.select-container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .select-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #eb2701;
  }

  .select {
    button {
      border: 1px solid rgba(2, 40, 94, 0.1) !important;
      svg {
        display: none;
      }
    }
  }

  .option {
    background: #ffffff;
    border: 1px solid rgba(2, 40, 94, 0.1);
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5) !important;

    &:hover {
      background: #02285e !important;
    }
  }
}

.campaign-budget-container {
  position: relative;
  width: 100%;

  .checkbox-container {
    position: absolute;
    width:fit-content !important;
    right: 0px;
    top: 5px;
    input{
      width: fit-content !important;
      margin-right: 4px !important;
    }

    label {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 13px;
      letter-spacing: -0.02em;
      color: #000000;
    }
  }
}

.disabled-input {
  background: rgba(163, 179, 201, 0.3);
  border: 1px solid rgba(2, 40, 94, 0.1);
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 100%;

  .select-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #eb2701;

  }
}

.location-input-container {
  position: relative;
}

.location-input {
  width: 100%;
  height: 40px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  padding: 0.5rem;
  font-size: 1rem;

  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #000000;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.5);
  }

  &:focus {
    outline: 1px solid #eb2701;
    border: 1px solid #eb2701;
  }
}

.location-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #ffffff;
  border: 1px solid rgba(2, 40, 94, 0.1);
  border-top: none;
  // border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.location-suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  
  &:hover {
    background: #02285e;
    color: #ffffff;
  }
}

.selected-locations {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;
}

.location-tag {
  display: inline-flex;
  align-items: center;
  background: #f0f2f5;
  border-radius: 16px;
  padding: 4px 10px;
  font-size: 14px;
  width: auto !important;
  margin-right: 5px;
  margin-bottom: 5px;
}

.remove-location {
  margin-left: 6px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  color: #666;
  
  &:hover {
    color: #eb2701;
  }
}
