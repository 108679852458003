.validate-container {
  text-align: center;
  width: 500px;
  height: 500px;

  * {
    display: block;
    margin: 0 auto;
  }

  .loading-text {
    font-size: 24px;
    margin: 20px auto;
  }

  .loading-three-circles {
    margin-bottom: 40px;
  }
}
