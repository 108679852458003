.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;
  }

  p {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #ffffff;
  }
}

.tabs {
  margin-top: 10px;
}

.tab-content {
  padding: 2rem;
  background: #fcfcfd;
}

.calendar {
  padding: 8px !important;
}

.comment-active-tab-content {
  cursor: url("./../../../public/Cursor.svg"), auto !important;
}

.tabs-container {
  position: relative;

  .actions {
    padding: 1rem;
    position: absolute;
    top: -16px;
    right: 10px;
    display: flex;
    gap: 1rem;

    .approve-btn {
      min-width: 123px;
      height: 42px;
      display: flex;
      align-items: center;
      gap: 10px;
      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        color: #ffffff;
      }
    }

    .comment-btn {
      min-width: 180px;
      height: 42px;
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 40px;
        color: #eb2701;
      }

      &:hover {
        p {
          color: #ffffff;
        }

        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }

    .comment-enable-btn {
      background-color: #eb2701;

      p {
        color: #ffffff;
      }

      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}

.comment {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: red;
  z-index: 2;
}
