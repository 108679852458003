.container {
  .sentiment {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .header {
      display: flex;
      align-items: flex-start;
      width: 100%;
      gap: 10px;

      svg {
        width: 31.5px !important;
        height: 30px !important;
        path {
          fill: #f98c28;
        }
      }

      .heading {
        color: var(--Primary-Base-Black, #000);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-left: 15px;
      position: relative;
      margin-bottom: 5px;

      &:before {
        content: "\2022";
        position: absolute;
        left: 0;
        color: #333;
      }
    }
  }
}

.pin-icon {
  position: absolute;
  right: 3%;
  top: 6%;
  cursor: pointer;
  height: 15px;
  z-index: 5;
}

.ellipses-icon {
  position: absolute;
  right: 5%;
  top: 6%;
  z-index: 5;
}
