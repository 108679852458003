.show-more-less {
  display: inline;

  .show-more-less-button {
    border: none;

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    span {
      color: #eb2701 !important;
    }
  }
}
