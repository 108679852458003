.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
  gap: 1rem;

  .gif {
    width: 255px;
    height: 255px;
  }

  .loading-text {
    color: #000;
    text-align: center;
    width: 400px;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px; /* 125% */
  }
}

.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
  0% {
    box-shadow: 20px 0 #eb2701, -20px 0 rgba(235, 39, 1, 0.6);
    background: rgba(235, 39, 1, 0.3);
  }
  33% {
    box-shadow: 20px 0 #eb2701, -20px 0 rgba(235, 39, 1, 0.6);
    background: rgba(235, 39, 1, 0.6);
  }
  66% {
    box-shadow: 20px 0 rgba(235, 39, 1, 0.6), -20px 0 #eb2701;
    background: rgba(235, 39, 1, 0.3);
  }
  100% {
    box-shadow: 20px 0 rgba(235, 39, 1, 0.3), -20px 0 #eb2701;
    background: #eb2701;
  }
}
