@use "../../../../styles/mixins.scss" as m;

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  // width: 100%;
  width: 100%;

  .title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #02285e;
  }

  .img {
    width: 100%;
    min-width: 70%;
    height: 100%;
    max-height: 100%;
  }
}
