.container {
  position: relative;
  overflow: hidden;
  height: 100%;

  .side-bar {
    position: fixed;
    left: 0px;
  }

  .header {
    width: 100%;
    padding-left: 100px;
  }

  .content {
    width: 100%;
    padding-left: 100px;
    overflow: auto;
  }
}
