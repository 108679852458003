.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .row-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .col-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    .content-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
    }
  }
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #02285e;
}

.content {
  background: #ffffff;
  border: 1px solid #eb2701;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  flex: 1;
}
