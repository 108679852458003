.container {
  padding: 1rem;
  background: var(--white, #fff);
  margin-top: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .input-field {
    label {
      color: #eb2701;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
      text-transform: capitalize;
    }

    .input {
      border: 1px solid rgba(2, 40, 94, 0.1);
      background: var(--white, #fff);
    }
  }

  .user-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .two-col {
      display: flex;
      gap: 1rem;

      .input-field {
        flex: 1;
      }
    }
  }

  .heading {
    color: #02285e;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
  }

  .divider {
    border: 1px solid #eb2701;
  }

  .user-info {
    display: flex;
    gap: 1rem;
  }

  .meta-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  .role {
    color: #778ba8;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .editable-input {
    display: flex;
    align-items: center;
    width: fit-content;
    input {
      flex: 1;
    }
  }
}

.header-text {
  color: var(--white, #fff);
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
}

.avatar {
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .change-avatar {
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    background: rgba(2, 40, 94, 0.5);
    overflow: hidden;

    p {
      color: var(--white, #fff);
      text-align: center;

      font-size: 16px;

      font-weight: 500;
      line-height: 44px; /* 275% */
    }
  }
}

.auto-width-input {
  font-size: 16px;
  width: auto;
  min-width: 400px;
  max-width: 100%;
  border: 1px solid #ccc;
  box-sizing: content-box;
  border: none;
  outline: none;

  color: #02285e;
  font-size: 32px;
  font-weight: 400;
  line-height: 28px;
}

.text-mirror {
  position: absolute;
  visibility: hidden !important;
  white-space: nowrap;
  font-size: 16px;
  padding: 5px;
}

.sub-heading {
  color: #02285e;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
}

.save-btn {
  max-width: 300px;
  align-self: flex-end;
  color: var(--white, #fff);

  font-size: 16px;

  font-weight: 700;
  line-height: 32px; /* 200% */
}
