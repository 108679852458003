.container {
  position: relative;
}

.comment-side-bar {
  right: 0px;
  top: 0px;
  height: 86vh;
}

.header-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;
}

.filter-container {
  padding-top: 2rem;
  display: flex;
  gap: 2rem;
}

.notification-row-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.notification-row {
  width: 99%;
  height: 60px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0 10px;
  gap: 1rem;
  button {
    min-width: 170px !important;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    transform: rotate(0.75deg);
  }

  &:hover {
    background-color: #ffefec;
  }
}

.approver-notification-row {
  &:hover {
    background: #e1fde9;
  }
}

.launched-notification-row {
  &:hover {
    background-color: #d7e6fa;
  }
}

.icon-title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  width: 200px;
  padding-left: 5px;
  padding-right: 40px;
}

.content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.icon-class {
  width: "24.212px";
  height: "25px";
}

.actions {
  margin-left: 10px;
  color: black;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.dot {
  height: 13px;
  width: 13px;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
}

.comment-badge {
  text-transform: uppercase;
  border-radius: 5px;
  background: #02285e;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 5px;
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.approvered-badge {
  border-radius: 5px;
  background: #18c148;
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-transform: uppercase;

  display: flex;
  gap: 4px;
  padding: 5px;
  align-items: center;
}

.need-approval-badge {
  text-transform: uppercase;
  border-radius: 5px;
  background: #eb8d01;
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  display: flex;
  gap: 4px;
  padding: 5px;
  align-items: center;
}
