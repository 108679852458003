.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    text-transform: capitalize;
    color: #02285e;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .follow-item {
      display: flex;
      align-items: flex-start;
      gap: 7px;
      margin: 3px 0px;

      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffe5e0;
        border-radius: 5px;
        padding: 3px;

        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }

      .text {
        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 17px;
          color: #000000;
        }

        
      }
    }
  }
}
