.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 299;
  height: 100%;
}

.modal {
  max-width: 578px;
  position: fixed;
  top: 10%;
  left: 35%;
  z-index: 300;
  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.content {
  padding: 20px;
}
