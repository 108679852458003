.container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 580px;
  margin: auto;
  z-index: 1000;

  top: 1.4%;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .image-container {
      position: relative;
      img {
        width: 100%;
        height: 450px;
        object-fit: cover;
        border-radius: 10px;
      }

      .change-media {
        position: absolute;
        right: 5px;
        top: 5px;
        min-width: 140px;
      }
    }

    input {
      border-radius: 5px;
      border: 1px solid rgba(2, 40, 94, 0.1);
      background: #fff;
      padding: 10px;
      font-family: Inter, sans-serif;
    }

    textarea {
      border: 1px solid rgba(2, 40, 94, 0.1);
      border-radius: 5px;
      resize: none;
      padding: 20px;
      font-family: Inter, sans-serif;
    }
  }

  .social-media-buttons {
    display: flex;
    gap: 20px;
    align-items: center;

    .social-media {
      display: flex;
      width: 237px;
      padding: 20px;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      border-radius: 10px;
      background: #fff;
      box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.05);

      .title {
        color: #030303;
        text-align: center;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }

      .description {
        color: #a3b3c9;
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 18px; /* 180% */
      }

      .icon {
        display: flex;
        width: 100px;
        height: 100px;
        padding: 5px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }

      &:hover {
        background: #f7f7f7;
      }
    }

    .facebook {
      .icon {
        border-radius: 10px;
        background: #0866ff;
      }
    }

    .linkedIn {
      .icon {
        border-radius: 10px;
        background: #0270ad;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title {
    color: #02285e;
    font-size: 32px;
    font-weight: 700;
    line-height: 20px;
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    display: flex;
    min-width: 95px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
}
