.landing-page {
  padding-left: 0px !important;
}

.marketing-funnel {
  display: flex;
  align-items: center;
  padding: 2rem;
  padding-top: 0px;
  margin-top: 150px;
  flex-direction: column;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;

  position: relative;

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
  }
}

.gif {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  height: 322px;
  width: 322px;
  z-index: 100;
  bottom: 25%;
  left: 9%;
}

.png{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  height: 322px;
  width: 322px;
  z-index: 100;
  bottom: 25%;
  left: 9%;
}

.funnel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  z-index: 100;
  margin-bottom: 25%;

  .stage-1 {
    position: relative;
    cursor: pointer;

    z-index: 8 !important;

    &:hover {
      .funnel-svg {
        path {
          &:nth-child(1) {
            fill: #a81607;
          }
        }
      }
    }
  }

  .stage-2 {
    position: relative;
    cursor: pointer;

    z-index: 4 !important;

    .funnel-svg {
      margin-top: -30px;
    }

    &:hover {
      .funnel-svg {
        path {
          &:nth-child(1) {
            fill: #eb2701;
          }
        }
      }
    }
  }

  .stage-3 {
    position: relative;
    cursor: pointer;

    z-index: 3 !important;

    .funnel-svg {
      margin-top: -30px;
    }

    &:hover {
      .funnel-svg {
        path {
          &:nth-child(1) {
            fill: #fc4c2a;
          }
        }
      }
    }
  }

  .stage-4 {
    position: relative;
    cursor: pointer;

    z-index: 2 !important;

    .funnel-svg {
      margin-top: -30px;
    }

    &:hover {
      .funnel-svg {
        path {
          &:nth-child(1) {
            fill: #ff7a60;
          }
        }
      }
    }
  }
}
