.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
}

.card {
  flex: 1 1 calc(25% - 1rem);
  max-width: calc(25% - 1rem);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid rgba(163, 179, 201, 0.2);
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
}

.card-header {
  display: flex;
  gap: 10px;
  align-items: center;
}

.title {
  color: #02285e;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.description {
  font-weight: 350 !important;
  line-height: 24px;
  flex-grow: 1;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
