.container {
  width: 100%;
  padding: 1rem;

  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo-container {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    p {
      color: #02285e;
      font-weight: 400;
      font-size: 16px;
      color: #02285e;
      align-self: flex-end;
    }

    svg{
      height: 30px;
      width: 25px;
    }
  }

  .campaign-name-input {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    text-align: center;
    width: 60%;
    margin-left: 10rem;
  }

  .save-share-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    .share-button {
      height: 40px;
      min-width: 95px;
    }

    .update-status-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.share-icon {
  cursor: pointer;
  height: 25px;
  width: 30px;
  fill: #eb2701;
}
