.container {
  background: #ffffff;
  border-radius: 0px;
}

.content {
  min-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      font-size: 24px;
      text-transform: capitalize;
      font-weight: 700;
      line-height: 30px; 
    }

    .message {
      font-size: 24px;

      font-weight: 400;
      line-height: 30px;
    }
  }
}
