.header-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #fff;
  padding-top: 5px;
}

.back-to-my-team-link {
  color: #fff;
  font-weight: 100;
}

.back-to-my-team-btn {
  float: right;
}

.modal {
  min-width: 540px;
  min-height: 220px;

  h1 {
    display: flex;
    align-content: center;
    justify-content: space-between;
    color: #02285e;
    font-size: 24px;
    padding-top: 15px;
    cursor: pointer;

    .modal-header {
      display: flex;
      gap: 15px;
      align-items: center;
      padding-bottom: 21px;

      svg {
        color: #2bd05a;
      }
    }

    .modal-close {
      color: #000;
      font-size: 20px;
    }
  }

  .modal-message {
    padding-bottom: 17px;
    overflow: hidden;
  }

  button {
    height: 40px;
    min-width: 213px;
    margin-bottom: 20px;
  }
}

.form-container {
  background-color: #fff;
  padding: 90px 45px;
  margin-top: 20px;
  width: 100%;

  .element-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 30px;
    justify-content: space-between;
    width: 100%;
  }

  .submit {
    float: right;
    height: 40px;
    min-width: 95px;
  }

  .input-half {
    width: calc(50% - 10px);
    width: 100%;
    flex: 1;
  }

  .element-full {
    width: 100%;
  }

  .description {
    padding-top: 50px;
    color: #778ba8;
  }
}

.select-role {
  button {
    border-radius: 5px 5px 0px 0px !important;
  }
}

.select-group {
  gap: 10px !important;
  .select-role {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #02285e;
  }
}
