.journey-management-container {
  background-color: #f9fafb; // Light background color for the container
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: flex-start;
}

.journey-tree-view {
  min-height: 100vh;  
}

.journey-list {
  flex-grow: 1;
  max-width: 800px;
  margin-right: 20px;
}

.journey-item {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.journey-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.journey-item h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

.journey-item p {
  color: #555;
}

.journey-item-actions button {
  margin-right: 10px;
}

.journey-sidebar {
  background-color: white;
  width: 350px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  transition: transform 0.3s ease-in-out;
}

.journey-sidebar .close-btn {
  background-color: #d1d5db;
  color: white;
}

.journey-sidebar .save-btn {
  background-color: #3b82f6;
  color: white;
}

.journey-sidebar h3 {
  margin-bottom: 20px;
}

.journey-sidebar input,
.journey-sidebar textarea,
.journey-sidebar select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 1rem;
}

.journey-sidebar textarea {
  height: 120px;
}

button:hover {
  opacity: 0.9;
}
