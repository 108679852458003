@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/mixins.scss" as m;
.container {
  border-radius: v.$border-radius-2;
  background: c.$white;
  width: fit-content;
  padding: v.$padding-5 v.$padding-9;

  @include m.sm {
    padding: v.$padding-4;
  }
}
