.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.label-container {
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #ffe5e0;
  }

  .label {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #02285e;
  }
}

.text {
  width: 100%;
  padding: 5px 10px;
  gap: 10px;
  border: 1px solid #eb2701;
  border-radius: 5px;
    font-weight: 300;
}
