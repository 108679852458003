.container {
  width: 100%;
  padding: 1rem;

  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: space-between;

  .back-container {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    p {
      color: #02285e;
      font-weight: 400;
      font-size: 16px;
      color: #02285e;
      align-self: flex-end;
    }
    svg {
      height: 12px;
      width: 16px;
    }
  }

  .campaign-name-input {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    text-align: center;
    width: 60%;
    margin-left: 10rem;
  }

  .submit-button {
    width: 220px;
    height: 40px;

    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
  }
}
