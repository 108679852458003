.container {
  border: 3px solid #eb2701;

  box-shadow: 0px 16px 16px 20px rgba(112, 144, 176, 0.05);
  padding: 1rem;

  border-radius: 10px;
  border: 1px solid rgba(163, 179, 201, 0.2);
  background: rgba(163, 179, 201, 0.1);

  display: flex;
  flex-direction: column;
  gap: 10px;

  min-height: 145px;

  .label {
    text-align: left;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    color: #000000;
  }

  .value {
    font-feature-settings: "clig" off, "liga" off;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    font-family: Inter, sans-serif;
    line-height: 2rem;
    color: #02285e;
  }

  .icon-container {
    min-height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
    background-color: #eb2701;

    border-radius: 15px;
    background: #fff;

    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      path {
        fill: #eb2701 !important;
      }
    }
  }
}
