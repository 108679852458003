.container {
  background: #ffffff;
  border-radius: 0px;
}

.content {
  min-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header { 
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }

    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      color: #02285e;
    }
  }

  .subjective-text {
    font-size: 13px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
  }

  .input:focus {
    outline: none;
    caret-color: black;
    padding: 1%;
  }

  .input {
    width: 100%;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid rgba(2, 40, 94, 0.15);
    line-height: 25px;
    height: 40px;
    font-size: 24px;
    padding: 0 10px;
  }

  .action-btn {
    min-width: 100px;
    align-self: flex-end;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
