.campaign-builder-layout {
  overflow: hidden !important;
  height: 100%;
}

.container {
  overflow: auto !important;
  height: 100%;
  position: relative;

  .header {
    height: 80px;
    z-index: 105;
    align-self: flex-start;
    position: fixed;
  }

  .content {
    position: relative;
    display: flex;
    overflow: hidden;
    margin-top: 80px;
    gap: 1rem;
    height: 91vh;
  }

  .side-bar {
    height: 100% !important;
    position: sticky;
  }
}

.container-strategy {
  overflow: auto !important;
  height: 100%; 
  position: relative;

  .header {
    height: 80px;
    z-index: 105;
    align-self: flex-start;
    position: fixed;
  }

  .content {
    position: relative;
    display: flex;
    overflow: hidden;
    margin-top: 80px;
    gap: 2rem;
    height: 91vh;
  }

  .side-bar {
    height: 100% !important;
    position: sticky;
  }

  .nurture-strategy-chat {
    width: 46% !important;
    overflow: hidden;
    min-height: 100%;
    // position: relative;
  }
}

.insights-report {
  width: 100%;
  overflow: auto; 
  padding: 1rem;
  padding-left: 0px;
  margin-right: -15px;
  padding-right: 2rem;
}
