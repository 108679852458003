/* LogList.module.scss */
.container {
  max-width: 90%;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .heading {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }

  ul {
    li {
      margin-top: 20px;
      button {
        background-color: #333;
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-right: 10px;
        &:hover {
          background-color: #555;
        }
      }
    }
  }
}

.log-details {
  overflow-x: auto !important;
}
