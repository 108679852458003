.container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;

  .body {
    display: flex;
    gap: 1px;
    flex-direction: row;
    flex: 1;
    width: 100%;
    overflow: hidden;
    // height: 100%;

    .vertical-tabs {
      width: 200px;
    }

    .model-response {
      flex: 1;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 10px;
      box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
      border-radius: 10px 10px 0px 0px;
      display: flex;
      flex-direction: column;
      position: relative;
      background: #fafafa;
      min-height: fit-content !important;
      overflow-y: auto;

      .title {
        background: #02285e;
        width: 100%;
        padding: 10px 16px;

        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        text-transform: uppercase;
        border-radius: 10px 10px 0px 0px;
        color: #ffffff;
        position: sticky;
        top: 0px;
      }

      .plan {
        padding: 0px;
        max-height: 100%;
        overflow-y: auto;
        margin: 10px !important; 
      }
    }
  }

  .header {
    height: 60px;
  }

  .content-expanded {
    .vertical-tabs {
      min-width: 30% !important;
      width: 30% !important;
      max-width: 30% !important;
    }

    .model-response {
      flex: 1;
    }
  }

  .content-collapsed {
    .vertical-tabs {
      max-width: 50px;
    }

    .model-response {
      flex: 1;
    }
  }
}

.marketing-brief-form-card {
  height: 100%;
  padding: 1rem;
  border: none !important;
  overflow-y: auto;
}

.campaign-query-chat {
  height: 100%;
  padding: 0rem 10px;
  padding-bottom: 10px;
}

  
.content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 10rem;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.loader-container {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 2rem;
  padding: 10px;
}

.loader {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #eb2701, -20px 0 rgba(235, 39, 1, 0.6);
    background: rgba(235, 39, 1, 0.3);
  }
  33% {
    box-shadow: 20px 0 #eb2701, -20px 0 rgba(235, 39, 1, 0.6);
    background: rgba(235, 39, 1, 0.6);
  }
  66% {
    box-shadow: 20px 0 rgba(235, 39, 1, 0.6), -20px 0 #eb2701;
    background: rgba(235, 39, 1, 0.3);
  }
  100% {
    box-shadow: 20px 0 rgba(235, 39, 1, 0.3), -20px 0 #eb2701;
    background: #eb2701;
  }
}

.tab-content {
  padding: 1rem;
}
