.container {
  background: #fff6eb;
  border: 3px solid #eb2701;
  border-radius: 20px 20px 20px 0px;
  position: absolute;
  z-index: 23;
}

.closeContainer {
  height: 69px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 3px solid #eb2701;
}

.openContainer {
  width: 500px;
  height: fit-content;
  padding: 12px 20px;
  z-index: 24;
}

.profileImages {
  display: flex;
  gap: 15px;
  border: 0px;
  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}

.comment-list {
  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row-reverse;
    span {
      display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 23px;
      width: 23px;
      border-radius: 50%;
      border: 2px solid #eb2701;

      svg {
        height: 15px;
        width: 15px;
        path {
          fill: #eb2701;
        }

        &:hover {
          path {
            fill: #fff;
          }
        }
      }

      &:hover {
        background: #eb2701;
      }
    }
    .close-icon {
      height: 23px;
      width: 18px;
      cursor: pointer;
    }
  }

  .divider {
    height: 1px;
    background: #eb2701;
    margin: 10px 0;
  }

  .comments {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    margin-top: 20px !important;
    max-height: 400px;
    overflow: auto;
    .comment {
      display: flex;
      gap: 10px;
      align-items: flex-start;
      img {
        height: 29px;
        width: 29px;
        border-radius: 50%;
      }

      .comment-content {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .comment-header {
          display: flex;
          gap: 10px;
          align-items: center;

          h4 {
            font-family: "Rubik", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }

          span {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #778ba8;
          }
        }

        .text {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #010101;
        }
      }
    }
  }
}

.input-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;

  img {
    height: 29px;
    width: 29px;
    border-radius: 50%;
  }

  .input {
    padding: 5px;

    background: #ffffff;
    border: 1px solid #02285e;
    border-radius: 5px;
    flex: 1;
    display: flex;
    align-items: center;
    input {
      border: none;
      outline: none;
      width: 100%;
    }
  }
}
