.container {
  overflow: hidden;
}

.campaign-builder {
  transition: right 0.3s ease;
  background-color: #fff !important;
  position: absolute;
  right: -19px;
  top: 0px;
  width: 96%;
  height: 100%;
  padding: 1rem;
  overflow: auto;
  z-index: 10;
  
  &.closed {
    width: 47%;
    .campaign-data {
      padding: 1rem;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
      gap: 1rem;
    }
    
  }

  &.open {
    width: 97%;
  }
}

.collapse-btn {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #778ba8;
  border-radius: 0px 5px 5px 0px;
  z-index: 11;
  position: absolute;
  top: 32px;
  right: 0px;

  &.closed {
    right: 42.7%;
  }

  &.open {
    left: 4.6%;
  }

  &:hover {
    cursor: pointer;
    background-color: #EB2701;
  }
}

.header {
  background: #eceef1;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  padding-left: 2.5rem;
  position: sticky;
  top:-16px;
  z-index: 10;

  .header-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-left: 10px;
  }

  .submit-btn {
    width: 177px;
    height: 40px;
    span {
      background-color: green;
      display: inline-block;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: white;
      }
    }

    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;

      display: flex;
      align-items: center;

      color: #ffffff;
    }
  }
}

.content {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 6rem;
}

.campaign-data {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  gap: 1rem;
  
}

.recommendation-data{
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr)) !important; 
  gap: 1rem;
}

.campaign-builder-content{
  margin-bottom: 5rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem;
}