.table {
  width: 100%;
  overflow: hidden;

  thead th {
    border: solid 1px #fff6eb;
    background-color: #02285e;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    height: 50px;
    padding: 12px;
  }

  tbody {
    td {
      border: solid 1px #fff6eb;
      background-color: #ffffff;
      color: #495057;
      border-bottom: 1px solid #dee2e6;
      height: 50px;
      padding: 12px;
    }
  }
}
