.container {
  max-height: 43% !important;
  overflow-y: auto;
}
   
.list-view-schedules {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  .schedules {
    max-height: 40%;
    overflow-y: auto;

    .date {
      font-weight: 400;
      font-size: 16px;
      line-height: 39px;
      letter-spacing: -0.04em;
      color: #778ba8;
    }

    .posts {
      display: flex;
      flex-direction: column;
      .post-container {
        display: flex;
        background: #ffffff;
        border: 1px solid rgba(163, 179, 201, 0.2);
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        height: 70px;
        .post {
          display: flex;
          align-items: center;
          gap: 1rem;

          height: 68px;
          width: 98%;

          .value-proposition {
            display: block;
            text-align: center;

            padding: 5px 14px;
            height: 30px;

            background: #287be7;
            border-radius: 10px;
            text-align: center;

            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;

            color: #ffffff;
          }

          .time {
            font-weight: 600;
            font-size: 16px;
            line-height: 12px;
            color: #02285e;
          }

          .text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 12px;
            color: #000000;
            overflow: hidden;
            // flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
          }

          img {
            width: 25px;
            height: 25px;
          }
        }

        .edit-icon {
          cursor: pointer;
          align-content: flex-end;
        }
      }
    }
  }
}
