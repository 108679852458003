.campaign-builder-layout {
  overflow: hidden !important;
  height: 100%;
}

.container {
  overflow: auto !important;
  height: 100%;

  .header {
    height: 80px;
    z-index: 105;
    align-self: flex-start;
  }

  .content {
    position: relative;
    display: flex;
    height: 100%;
    overflow: hidden;
  }

  .side-bar {
    height: 60% !important;
  }
}
