
.header-text {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;
}

.campaign-report {
  margin-top: 1rem;
  padding: 1rem;
}
