.table {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  input[type="checkbox"] {
    /* Hide the default checkbox appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Define custom styles for the checkbox */
    width: 20px;
    height: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }

  input[type="checkbox"]:checked {
    background-color: #eb2701;
  }
}

.table {
  thead {
    th {
      padding: 1rem;
      text-align: left;
      text-transform: uppercase;

      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: #000000;

      background: #eceef1;

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }
}

.table {
  tbody {
    tr {
      border: 1px solid rgba(0, 0, 0, 0.1);

      &:first-child {
        border-top: 0px solid rgba(0, 0, 0, 0.1);
      }
    }
    td {
      padding: 1rem;

      background: #ffffff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: #000000;
    }
  }
}

.status-button {
  background: var(--eriko-blue, #02285e);
  border-radius: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  height: 30px;
  cursor: pointer;
  font-size: 0.875em;
  color: white;
  font-weight: 800;
  text-align: center;

  &.needs-approval {
    background-color: #02285e;
  }

  &.approved {
    background-color: #dc3545;
  }

  &.active {
    background-color: #28a745;
  }
}
