.card {
  //   padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.header {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  width: 100%;
  padding: 16px;
  border-radius: 8px 8px 0 0;

  .icon {
    position: relative;

    .triangle {
      position: absolute;
      right: -12%;
      bottom: 1%;
    }
  }

  .stats {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .customerCount {
      font-weight: 700;
      font-size: 36px;
      line-height: 32px;
      color: #02285e;
    }

    .headerText {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }
}

.alert {
  background: rgba(235, 39, 1, 0.2);
  border-radius: 10px 10px 0px 0px;

  .icon {
    .triangle {
      fill: #eb2701;
      path {
      }
    }
  }
}

.warning {
  background: rgba(255, 158, 2, 0.2);
  border-radius: 10px 10px 0px 0px;
}

.success {
  background: rgba(38, 197, 49, 0.2);
  border-radius: 10px 10px 0px 0px;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  padding: 1rem;
}

.metric {
  font-weight: 300;
  strong {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #02285e;
  }
}

.divider {
  border: 0.5px solid rgba(235, 39, 1, 0.5);
}

.generateButton {
  margin-bottom: 16px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
}
