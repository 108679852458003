.container {
  width: 80px;
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 100vh !important;
}

.icon-container {
  width: 100%;
  border-radius: 5px;
  background: rgba(235, 39, 1, 0.1);
  cursor: pointer;

  display: flex;
  height: 60px;
  padding: 22px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  svg {
    path {
      fill: #eb2701 !important;
    }
  }

  &.active {
    background: #eb2701;
    svg {
      path {
        fill: #fff !important;
      }
    }
  }
}
