// LAYOUT DESKTOP VARIABLES
$header-height: 104px;
$sidebar-width: 314px;
$lateral-padding: 15%;

// LAYOUT TABLET VARIABLES
$header-tablet-height: 104px;
$sidebar-tablet-width: 50px;
$lateral-tablet-padding: 10%;

// LAYOUT PHONE VARIABLES
$header-phone-height: 104px;
$sidebar-phone-width: 50px;
$lateral-phone-padding: 5%;

$border-radius-full: 9999px;

// BORDERS
$border-radius-1: 5px;
$border-radius-2: 10px;
$border-radius-3: 15px;
$border-radius-4: 20px;
$border-radius-5: 40px;
$border-radius-6: 35px;

// FONTS 
$font-size-xxs: 0.75rem; // 12px
$font-size-xs: 0.875rem; // 14px
$font-size-sm: 1rem; // 16px ---> BASE FONT SIZE
$font-size-md: 1.125rem; // 18px
$font-size-lg: 1.25rem; // 20px
$font-size-xl: 1.5rem; // 24px
$font-size-xxl: 1.6875rem; // 27px
$font-size-xxxl: 1.875rem; // 30px
$font-size-xxxxl: 3rem; // 48px
 
//PADDING
$padding-1: 0.3125rem; // 5px
$padding-2: 0.5rem; // 8px
$padding-3: 0.625rem; // 10px
$padding-4: 0.75rem; // 12px
$padding-5: 1rem; // 16px
$padding-6: 1.125rem; // 18px
$padding-7: 1.25rem; // 20px
$padding-8: 1.375rem; //22px
$padding-9: 1.5625rem; // 25px
$padding-10: 1.875rem; // 30px
$padding-11: 2.1875rem; // 35px
$padding-12: 2.5rem; // 40px
$padding-13: 2.8125rem; // 45px
$padding-14: 3.125rem; // 50px
$padding-15: 3.4375rem; // 55px
$padding-16: 3.75rem; // 60px
$padding-17: 4.0625rem; //  65px

//MARGIN
$margin-1: 0.3125rem; // 5px
$margin-2: 0.5rem; // 8px
$margin-3: 0.625rem; // 10px
$margin-4: 1rem; // 16px
$margin-5: 1.25rem; // 20px
$margin-6: 1.5625rem; // 25px
$margin-7: 1.875rem; // 30px
$margin-8: 2.1875rem; // 35px
$margin-9: 2.5rem; // 40px
$margin-10: 2.8125rem; // 45px
$margin-11: 3.125rem; // 50px
$margin-12: 3.4375rem; // 55px
$margin-13: 3.75rem; // 60px
$margin-14: 4.0625rem; // 65px
$margin-21: 6.25rem; // 100px

// Z-INDEX

$z-modal: 900;
$z-dropdown: 800;
$z-sider: 850;

//TRANSITION

@mixin transition-opacity {
  transition: opacity 100ms ease-in;
}

@mixin transition-colors {
  transition: 150ms ease-in;
  transition-property: color, background-color, fill, stroke, border-color;
}

@mixin transition {
  transition-duration: 150ms;
  transition-timing-function: ease-in;
}
