.container {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #02285e;
  }

  .highlight {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  ul {
    list-style-type: disc;
    margin-top: 5px;
    margin-left: 20px;

    li {
      list-style: disc;
      padding-bottom: 5px;
      font-size: 16px;
      font-family: "Inter", sans-serif;
    }
  }
}
