@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;
@use "../../styles/mixins.scss" as m;

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #fafafa;
  width: 100%;
}

.sub-header {
  text-transform: uppercase;
  color: #eb2701;
  font-family: "Inter", sans-serif;
}

.sub-heading {
  align-self: flex-start !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 22px;
  color: #02285e;
}

.filters {
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  .select-menu {
    min-width: fit-content;
    .select-button {
      border-radius: 5px;
      min-width: fit-content !important;
      max-width: 400px !important;

      white-space: nowrap; /* Prevents text from wrapping */
      overflow: hidden; /* Hides overflow text if any */
      text-overflow: ellipsis; /* Adds ellipsis for overflow text */
    }
  }
}

.findings {
  display: flex !important;
  gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .metric {
    width: 100%;
  }
  .white-background {
    background: #fff;
    .icon-container {
      border-radius: 15px;
      background: #eb2701;
      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }
}

.three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.graph-container {
  min-height: 375px !important;
  max-height: 375px !important;
  canvas {
    width: 100% !important;
    min-height: 445px !important;
    max-height: 445px !important;
  }

  div {
    overflow: hidden !important;
  }
}

.create-strategy-boxes {
  display: flex;
  gap: 10px;
}

.channels-container {
  display: flex;
  gap: 10px;
  .channel {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .channel-title {
      color: #02285e;
      font-size: 20px;
      font-weight: 500 !important;
    }

    .channel-metrics {
      display: flex;
      gap: 10px;

      .metric {
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(163, 179, 201, 0.2);
        background: #fff;
        min-height: 160px;
        height: 100%;
        .icon-container {
          border-radius: 15px;
          background: #eb2701;
          svg {
            path {
              fill: #fff !important;
            }
          }
        }
      }
    }
  }
}
