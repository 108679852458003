.header-text {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;

  button {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 310 !important;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
  }
}

.container {
  padding: 1rem 0rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.high-risk-container {
  display: flex;
  gap: 1rem;
  width: 100%;
  padding: 2rem;

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 20px;

    background: rgba(255, 229, 224, 0.31);
    border-radius: 20px;

    .risk-stats {
      display: flex;
      align-items: center;
      gap: 1rem;

      .value {
        font-weight: 700;
        font-size: 80px;
        line-height: 32px;
        color: #eb2701;
      }

      .label {
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        color: #02285e;
        width: 150px;
        text-align: center;
      }
    }
  }

  .overview-container {
    width: 100%;
    .overview-heading {
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      color: #eb2701;
    }

    .overview-content {
      display: flex;
      gap: 5px;
      flex-direction: column;
      margin-top: 20px;
      .overview-item {
        display: flex;
        gap: 1rem;
        align-items: center;
        .label {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: #02285e;

          width: 180px;
        }

        .value {
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid rgba(2, 40, 94, 0.05);
      }
    }
  }
}

.key-findings {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(1, 25, 60, 0.05);
  border-radius: 20px;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #02285e;
  }

  .findings {
    display: flex !important;
    gap: 1rem;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.offers-btn {
  max-width: 366px;
  height: 50px;

  margin-top: 20px;

  font-family: "Inter",sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
