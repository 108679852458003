@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;
@use "../../styles/mixins.scss" as m;

.button {
  display: flex;
  min-width: 220px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: v.$border-radius-2;

  color: c.$white;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;

  @include m.sm {
    font-size: v.$font-size-xxs;
    width: 190px;
    padding: v.$padding-2;
  }

  cursor: pointer;
  border: none;
  outline: none;

  svg {
    width: 16px;
    height: 32px;
    flex-shrink: 0;

    @include m.sm {
      width: 12px;
      height: 22px;
    }
  }

  &:disabled {
    background-color: c.$ship-cove !important;
    cursor: not-allowed;
  }

  &.primary {
    background-color: c.$mid-night-blue;

    &:hover {
      background-color: c.$bright-red;
    }
  }

  &.secondary {
    background-color: c.$bright-red;
    background-color: c.$mid-night-blue;

    &:hover {
      background-color: c.$bright-red;
    }
  }

  &.outline {
    background-color: c.$white;
    color: c.$mid-night-blue;
    border: 1px solid c.$mid-night-blue;

    background: #ffffff;
    border: 1px solid #eb2701;
    border-radius: 10px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #eb2701;

    &:hover {
      background-color: #eb2701;
      color: c.$white;
    }
  }
}
