.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  border-radius: 10px;
background: #FFF;
box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05);

  position: relative;

  padding: 10px;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
      color: #02285e;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 160% */
    }
  }
}

.info-icon {
  position: absolute;
  top: 5px;
  right: 8px;
  cursor: pointer;
}
