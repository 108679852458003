.container {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .content{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #02285e;
  }

  .body-text {
    ol {
      counter-reset: item;
      list-style-type: none;
      padding-left: 0;
      .list-item {
        position: relative;
        margin-left: 1rem;
        
        strong {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px !important;
          line-height: 24px !important;
          color: #000000;
          margin: 0px !important;
        }

        &::before {
          content: counter(item) ".";
          counter-increment: item;
          position: absolute;
          left: -1em;
          font-weight: bold;
        }

        strong {
          display: block;
          font-size: 1.2em;
          margin-bottom: 0.5em;
        }

        ul {
          list-style-type: disc;
          margin: 0 2em !important;

          li {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
        }
      }
    }
  }
}
