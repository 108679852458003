.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .toggle-btn{
    width: fit-content;
    .switch-filter {
      display: flex;
      gap: 10px;
      align-items: center;
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        /* identical to box height, or 162% */
        letter-spacing: -0.02em;
  
        /* Primary/Base/Black */
        color: #000000;
      }
    }
  }

  

  .show-detail-btn {
    color: #eb2701;
    font-family: Inter,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    text-decoration-line: underline;
    text-align: start;
    width: 90px;
;
  }
}

.modal {
  min-width: 95vw;
  left: 3%;
  top: 1%;
  height: 98vh;
  overflow-y: auto;

}

.swot-analysis-comparison-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;

  .swot-tag {
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;

    color: #fff6eb;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 20px; /* 50% */
    text-transform: capitalize;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 10px;

    li {
      padding-left: 15px;
      position: relative;
      margin-bottom: 5px;
      &:before {
        content: "\2022"; /* Unicode for bullet */
        position: absolute;
        left: 0;
        color: #333; /* Color of the bullet */
      }
    }
  }

  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #02285e;
  }

  .strengths {
    border-radius: 10px;
    background: rgba(56, 176, 90, 0.11);
    position: relative;

    .content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: hidden;
      margin-bottom: 65px;
    }

    .heading {
      color: var(--green, #38b05a);
      text-transform: capitalize;
    }

    .swot-tag {
      align-self: flex-end;
      position: absolute;
      bottom: 0px;
      right: 0px;
      background: #38b05a;
      color: white;
      padding: 5px 10px;
      border-radius: 10px 0px 0px 0px;
    }
  }

  .weaknesses {
    border-radius: 10px 10px 10px 0px;
    background: rgba(235, 141, 1, 0.1);
    position: relative;

    .content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: hidden;
      margin-bottom: 68px;
    }
    .swot-tag {
      align-self: flex-start;
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: #eb8d01;
      color: white;
      padding: 5px 10px;
      border-radius: 0px 10px 0px 0px;
    }

    .heading {
      color: #eb8d01;
      text-transform: capitalize;
    }
  }

  .opportunities {
    border-radius: 10px 0px 10px 10px;
    background: rgba(2, 40, 94, 0.1);
    position: relative;
    .content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: hidden;
      margin-top: 65px;
    }
    .swot-tag {
      position: absolute;
      top: 0px;
      right: 0px;
      background: #02285e;
      color: white;
      padding: 5px 10px;
      border-radius: 0px 0px 0px 10px;
    }
    .heading {
      color: #02285e;
      text-transform: capitalize;
    }
  }

  .threats {
    border-radius: 0px 10px 10px 10px;
    background: rgba(235, 39, 1, 0.1);
    position: relative;
    .content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: hidden;
      margin-top: 65px;
    }
    .swot-tag {
      position: absolute;
      top: 0px;
      left: 0px;
      background: #eb2701;
      color: white;
      padding: 5px 10px;
      border-radius: 0px 0px 10px 0px;
    }
    .heading {
      color: #eb2701;
      text-transform: capitalize;
    }
  }
}
