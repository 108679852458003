.custom-select {
  position: relative;
  width: 265px;
  height: fit-content;

  .select-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
 
    
    padding: 10px 7px;
    text-transform: capitalize;

    background: #ffffff;
    border: 1px solid #eb2701;

    color: #778ba8;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Inter,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.889px; 
    letter-spacing: -0.268px;

    svg {
      fill: #eb2701 !important;
      height: 10px;
      width: 15px;
      path {
        fill: #eb2701;
      }
    }
  }

  .option-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;

    text-transform: capitalize;
    position: absolute;
    max-height: 333px;
    z-index: 5;
    top: 42px;
    left: 0;
    right: 0;
    height: fit-content;

    margin: auto;
    overflow-y: auto;

    text-transform: capitalize;
    cursor: pointer;

    .option {
      display: flex;
      align-items: center;

      box-sizing: border-box;

      background: #ffffff;
      // border: 1px solid #eb2701;

      font-family: "Inter",sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: #121212;
      padding: 10px;
      width: 100%;
      &:hover {
        background-color: #eb2701;
        color: #ffffff !important;
      }
    }

    .disabled-option {
      background: #e7edf6;
      border: 1px solid rgba(2, 40, 94, 0.1);
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.placeholder-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.268333px;
  color: #778ba8;
}
