.header-text {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;

  button {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 310 !important;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
  }
}

.content{
    width: 100%;
    // padding-left: 40px !important;
}

.report {
  width: 100%;
  overflow: auto;
  padding: 1rem;
}
