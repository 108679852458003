.container {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  .side-bar {
    position: fixed;
    left: 0px;
  }

  .header {
    width: 100%;
    padding-left: 75px;
  }

  .content {
    width: 100%;
    padding-left: 70px;
  }
}
