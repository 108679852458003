.container {
  border-radius: 8px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 2%;
  left: 0%;
  z-index: 300;

  position: absolute;

  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.open {
  display: block;
}

.close {
  display: none;
}

.text {
  margin-top: 5px;
  border-radius: 10px;
  background: #fff6eb;
  padding: 10px;
}
