.container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;

  .body {
    display: flex;
    gap: 1px;
    flex-direction: row;
    flex: 1;
    width: 100%;
    overflow: hidden;
    // height: 100%;

    .vertical-tabs {
      width: 200px;
    }

    .model-response {
      flex: 1;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 10px;
      box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
      border-radius: 10px 10px 0px 0px;
      display: flex;
      flex-direction: column;
      position: relative;
      background: #fafafa;
      min-height: fit-content !important;
      overflow-y: auto;

      .title {
        background: #02285e;
        width: 100%;
        padding: 10px 16px;

        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        text-transform: uppercase;
        border-radius: 10px 10px 0px 0px;
        color: #ffffff;
        position: sticky;
        top: 0px;
      }

      .plan {
        padding: 0px;
        max-height: 100%;
        overflow-y: auto;
        margin: 10px !important;
        padding-bottom: 50px !important;
        border-radius: 0px !important;
        padding-bottom: 200px !important;
      }
    }
  }

  .header {
    height: 60px;
  }

  .content-expanded {
    .vertical-tabs {
      min-width: 30% !important;
      width: 30% !important;
      max-width: 30% !important;
    }

    .model-response {
      flex: 1;
    }
  }

  .content-collapsed {
    .vertical-tabs {
      max-width: 50px;
    }

    .model-response {
      flex: 1;
    }
  }
}

.campaign-query-chat {
  height: 100%;
  padding: 0rem 10px;
  padding-bottom: 10px;
}

.customer-campaign {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: white;
  padding: 5px 1rem;
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 25px;
}

.generated-offers {
  padding: 5px 1rem;
}

.closure-header {
  background: none !important;
  padding: 7px 3px !important;

  border-bottom: 2px solid #eb2701 !important;
  p {
    color: #02285e;
    font-size: 20px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 24px;
    text-transform: capitalize !important;
  }
}

.closure-content {
}

.open-closure-header {
  svg {
    path {
      fill: #02285e;
    }
  }
}

.selected-offer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.change-offer-button {
  max-width: 100px !important;
  min-width: 110px !important;
}

.overview-card {
  margin-top: 5px;
  padding: 1rem 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .title {
    color: #02285e;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    text-transform: capitalize;
  }

  .description {
  }
}

.build-journey-button {
  margin-top: 10px;
  margin-left: 10px;
  min-width: 130px;
}
