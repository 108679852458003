.listing-card {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .list-heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    color: #02285e;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 10px;
    // padding-top: px;

    li {
      padding-left: 15px;
      position: relative;
      margin-bottom: 5px;
      line-break: anywhere;

      .text {
        // display: flex;
        gap: 5px;
        align-items: flex-start;
        span {
          // display: block;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #000;
          width: fit-content;
          white-space: nowrap; /* Prevent heading from breaking */
        }
      }

      &:before {
        content: "\2022"; /* Unicode for bullet */
        position: absolute;
        left: 0;
        color: #333; /* Color of the bullet */
      }
    }
  }
}
