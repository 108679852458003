@keyframes expandAnimation {
  from {
    width: 60px;
  }
  to {
    width: 300px;
  }
}

@keyframes collapseAnimation {
  from {
    width: 300px;
  }
  to {
    width: 60px;
  }
}

.container {
  z-index: 200;
  position: relative;

  &.expand {
    width: 300px;
    animation: expandAnimation 0.3s forwards;
  }

  &.collapse {
    width: 0px;
    animation: collapseAnimation 0.3s forwards;
  }

  .expand-sidebar {
    width: 100%;
    height: 100vh;
    background: #ffffff;

    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 1rem;

    .marketing-section,
    .customer-section,
    .sales-section,
    .discover-section,
    .operational-section {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .discover-section {
      .sidebar-item {
        .aqua-icon {
          // fill: #eb2701;
        }
      }

      .sidebar-item-active {
        .aqua-icon {
          path {
            stroke: #ffffff !important;
          }
        }
      }
    }

    .operational-section {
      align-self: flex-start;
      position: absolute;
      bottom: 20px;

      display: flex;
      flex-direction: column;
      gap: 5px !important;
    }

    .sidebar-item {
      display: flex;
      gap: 10px;
      align-items: center;
      border-radius: 0.5rem;
      cursor: pointer;
      padding: 8px 1rem;
      .icon {
        width: 13px;
        height: 13px;
      }

      p {
        font-weight: 700;
        font-size: 12px;
        line-height: 13px;
        color: #333;
      }

      &:hover {
        background: rgba(235, 39, 1, 0.1);
      }
    }

    .sidebar-item-active {
      background: #eb2701;

      .icon {
        path {
          fill: #ffffff;
        }
      }

      p {
        color: #ffffff;
        font-weight: 400;
      }

      &:hover {
        background: #eb2701;
      }
    }

    .logo-container {
      .logo {
        height: 35px;
        width: 42px;
      }

      .text-icon {
        height: 15px;
        width: 84px;
      }
    }

    .user-info {
      background: #fff6eb;
      border-radius: 10px;
      padding: 0.7rem 1rem;
      display: flex;

      .user-img {
        height: 40px;
        width: 40px;
        object-fit: contain;
      }

      .meta-data {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .user-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 100%;
          color: #000000;
        }

        .user-email {
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          color: #eb2701;
        }
      }
    }

    .campaign-btn,
    .team-member-btn {
      display: flex;
      flex-direction: row;
      gap: 16px;
      min-width: 100% !important;
      height: 50px;
      background: #eb2701 !important;
      border-radius: 10px;

      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        color: #ffffff !important;
      }
    }
  }

  .collapse-sidebar {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;
    height: 100vh;

    background: #ffffff;

    .marketing-section,
    .discover-section {
      margin-top: -18px;
    }

    .discover-section {
      .sidebar-item {
        .aqua-icon {
          // fill: #eb2701;
        }
      }

      .sidebar-item-active {
        .aqua-icon {
          width: 20px;
          path {
            stroke: #ffffff !important;
          }
        }
      }
    }

    .customer-section,
    .sales-section,
    .operational-section,
    .marketing-section,
    .discover-section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
    }

    .operational-section {
      bottom: 30px;
      margin-top: auto;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .sidebar-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 100%;
      &:first-child {
        margin-top: 1rem;
      }

      .icon {
        height: 14px;
        width: 15px;
        fill: #eb2701;
      }
    }

    .sidebar-item-active {
      background: #eb2701 !important;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        // height: 20px;
        width: 20px;
        path {
          fill: #ffffff;
        }
      }
    }

    .campaign-builder-item {
      background: rgba(235, 39, 1, 0.1);
      display: flex;
      align-items: center;
      padding: 1rem;
      border-radius: 0.5rem;

      &:first-child {
        margin-top: 1rem;
      }

      cursor: pointer;
      .outsource-svg {
        height: 20px;
        width: 20px;
        fill: #eb2701;
      }
    }

    .logo-container {
      .logo {
        height: 35px;
        width: 42px;
      }
    }

    .user-info {
      background: #fff6eb;
      border-radius: 10px;
      padding: 5px;
      height: 50px !important;
      width: 50px !important;
      .user-img {
        height: 40px;
        width: 40px;
        object-fit: contain;
      }
    }

    .campaign-btn,
    .team-member-btn {
      display: flex;
      flex-direction: row;
      gap: 16px;
      height: 50px;
      height: 50px;
      min-width: 50px !important;
      background: #eb2701 !important;
      border-radius: 10px;
    }
  }
}

.divider {
  width: 100%;
  border: 1px solid rgba(51, 51, 51, 0.15);
}

.dropdown-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;

  .updown-arrow {
    cursor: pointer;
  }
}
