@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/mixins.scss" as m;

.container {
  border-radius: 10px;
  border: 2px solid c.$bright-red;
  background: c.$white;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  gap: 1rem;

  @include m.sm {
    padding: 5px 10px;
  }

  // svg {
  //   width: 1.2rem;
  //   height: 1.3rem;
  //   flex-shrink: 0;
  //   cursor: pointer;

  //   @include m.sm {
  //     width: 0.8rem;
  //     height: 0.8rem;
  //   }
  // }

  input {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    width: 100%;
    border: none;
    outline: none;
    overflow: hidden;

    @include m.sm {
      font-size: 0.7rem;
      line-height: 1.2rem;
    }
  }
}

.action-icon {
  width: 1.2rem;
  height: 1.3rem;
  flex-shrink: 0;
  cursor: pointer;

  @include m.sm {
    width: 0.8rem;
    height: 0.8rem;
  }
}
