.header-text {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;

  button {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 310 !important;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
  }
}


.container {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.risk-analysis-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.hot-insight {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px !important;
  line-height: 28px !important;
  color: #ffffff !important;
}
