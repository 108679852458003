.container {
  background-color: #ffffff;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0px 1rem;
  padding-bottom: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  background-color: #ffffff;
  position: relative;
  height: 50px;
  border-radius: 10px;
  .icon {
    height: 16px;
    width: 20px;
  }

  .name {
    line-height: 40px;
    flex: 1;
  }
}

.ellipses {
  color: #000000;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 0.1px;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  position: relative;
  display: flex;
  //   padding: 0px 16px;
  flex-direction: column;
}

.items {
  position: absolute;
  top: 30px;
  left: 90%;
  display: flex;
  flex-direction: column;
  z-index: 2;

  .item {
    background: #ffffff;
    border: none;
    width: 180px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #000000;
    text-align: left;
    padding-left: 1rem;

    cursor: pointer;
    &:hover {
      background: #EB2701;
      color: white;
    }
  }
}
