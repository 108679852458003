.container {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #02285e;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .slide {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      .slide-content {
        width: 100%;
        padding: 8px;
        border: 1px solid #eb2701;
        border-radius: 5px;
        ul {
          list-style-type: disc;
          margin: 0 1em !important;

          li {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
        }
      }
    }
  }
}
