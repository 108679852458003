.toolbox {
  background: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .group {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;

    .heading{
      font-weight: 600;
    }
  }

  .entry {
    cursor: grab;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    height: 62px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    .icon {
      display: flex;
      padding: 19px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      border-radius: 10px 0px 0px 10px;
      background: rgba(163, 179, 201, 0.5);
    }

    .text {
      .title {
        color: #030303;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }

      .description {
        color: #a3b3c9;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 180% */
      }
    }
  }

  .entry--start {
    .icon {
      background: rgba(56, 176, 90, 0.2);
    }
  }

  .entry--trigger {
    .icon {
      background: rgba(24, 162, 242, 0.2);
    }
  }

  .entry--delay {
    .icon {
      padding: 22px;
      background: rgba(156, 39, 176, 0.2);
    }
  }

  .entry--check {
    .icon {
      padding: 13px;
      background: rgba(235, 141, 1, 0.2);
    }
  }

  .entry--end {
    .icon {
      padding: 19px;
      background: rgba(235, 39, 1, 0.2);
    }
  }
}

.toolbox-title {
  color: #02285e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.save-button {
  min-width: 100px;
}
