.node {
  border-radius: 10px;
  background: #fff;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 20px;

  .step-label {
    color: #000;
    font-weight: 500;
    line-height: 24px;
  }

  .step-description {
    color: #000;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
  }

  .icon {
    display: flex;
    width: 30px;
    height: 30px;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .body {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 10px;
    background: rgba(2, 40, 94, 0.05);
    .label {
      color: #02285e;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }

    .value {
      font-size: 12px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
    }

    .img{
      width: 100%;
      height: 100%;
    }
  }
}

.entry-point-node {
  width: 280px;
  .icon {
    background: rgba(56, 176, 90, 0.2);
  }
}

.email-point-node {
  width: 280px;

  .icon {
    background: rgba(2, 40, 94, 0.1);
  }
}

.notification-point-node {
  width: 280px;

  .icon {
    background: rgba(2, 40, 94, 0.1);
  }
}

.post-point-node {
  width: 280px;

  .icon {
    background: rgba(2, 40, 94, 0.1);
  }
}

.trigger-point-node {
  width: 280px;
  .icon {
    border-radius: 10px;
    background: rgba(24, 162, 242, 0.2);
  }
}

.check-point-node {
  width: 280px;
  .icon {
    border-radius: 10px;
    background: rgba(235, 141, 1, 0.2);
  }
}

//border-radius: 10px 0px 0px 10px;
// background: rgba(24, 162, 242, 0.20);

.end-point-node {
  width: fit-content;
  .icon {
    border-radius: 10px;
    background: #ffe5e0;
  }
}

.delay-point-node {
  width: 280px;

  .icon {
    border-radius: 5px;
    background: rgba(156, 39, 176, 0.2);
  }
}

.show-more {
  button {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    line-height: 12px;
  }
  span {
    font-size: 12px;
    line-height: 12px;
    font-weight: 450;
  }
}
