@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/mixins.scss" as m;
.content-text {
  font-size: 1rem;
}

.popover {
  align-self: flex-end;
  position: relative;

  .popover-btn {
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    width: 100%;
  }

  .chat-container {
    position: absolute;
    display: flex;
    top: 12px;
    right: 0px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 2rem;
    width: 750px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    z-index: 100;
    height: 650px;
    border-radius: 20px;
    background: #f2f4f5;
    box-shadow: -6px 6px 17.5px 2px rgba(0, 0, 0, 0.1);
  }

  @include m.sm {
    width: 100%;
    height: 100%;
    top: 0px;
    border-radius: 0px;
    box-shadow: none;
    .chat-container {
      border-radius: 0px;
      box-shadow: none;
    }
  }
}

.close-button {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28.166px;
  height: 28.166px;
  border-radius: 50%;
  background-color: #02285e;
  position: absolute;
  top: 6px;
  right: -6px;
  z-index: 200;
}

.close-button:hover {
  background-color: #eb2701;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  gap: 1.5rem;
  overflow: auto;
  max-height: 100%;
  flex: 1;

  .chat {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;
    flex: 1;
    overflow: auto;
    .left {
      align-self: flex-start;
      p {
        font-size: 1rem;
      }
      svg {
        height: 40px;
        width: 40px;
      }
    }

    .right {
      align-self: flex-end;
      p {
        font-size: 1rem;
      }
      svg {
        height: 40px;
        width: 40px;
      }
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .action-list {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
    }
  }
}
 
.msg-content {
  padding: 0.5rem 1rem;
  margin-top: 0px;
  max-width: 550px;
}

.msg-text {
  line-height: 1.2rem;
  word-wrap: anywhere;
}

.input-container {
  padding: 5px 15px;
  input {
    font-size: 1rem;
  }

  svg {
    height: 20px;
    width: 20px;
  }
}

.visulization-img {
  height: 500px;
  width: 500px;
}

.insight-response {
  color: #000 !important;
  font-weight: 400 !important;

  h1 {
    color: #333;
  }

  h2 {
    color: #555;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
}

.sentence {
  margin-bottom: 10px;
}

.listItem {
  list-style-type: square;
}

.message-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .actions {
    display: flex;
    flex-direction: row-reverse;
    gap: 0.5rem;
    margin-right: 20%;

    .add-to-report-btn {
      width: 200px;
      height: 100%;
      position: relative;
    }

    .report-dropdown-wrapper {
      width: 100%;
      position: absolute;
      top: -115%;

      .selected-report-btn {
        position: relative;
        border-radius: 10px 10px 10px 10px;
        padding: 5px;
        font-size: 14px;
      }

      .existing-eport-btn {
        border-radius: 10px 10px 10px 10px;
        background-color: rgb(191, 185, 185);
        padding: 5px;
        font-size: 14px;
      }

      .menu-items {
        position: absolute;
        top: -100%;
      }

      .sub-menu-items {
        position: absolute;
        top: -500%;
        left: -100%;
        height: 200px;
        overflow-y: scroll;
        border: none;
      }

      .sub-menu-container {
        position: relative;
        width: 100%;
      }
    }
  }
}

.eriko-chat__button-container {
  border-radius: 10px;
  border: 2px solid c.$bright-red;
  background: c.$white;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.15);
  margin: 1rem 0rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 4px 10px;
  cursor: pointer;
  width: fit-content !important;

  .icon {
    width: 47.826px;
    height: 40px;
    flex-shrink: 0;
  }

  .text {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.response-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .actions {
    margin-right: 0px !important;
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 10px;
    .action-icon {
      height: 1rem !important;
      width: 1rem !important;
      cursor: pointer;
      path {
        fill: #b2bbca;
      }
    }

    .action-icon:hover {
      path {
        fill: #eb2701;
      }
    }
  }
}

.propensity-container {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;

  .actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .icon {
      height: 20px;
      width: 20px;
      path {
        fill: white;
      }
    }
    button {
      width: 240px;
      svg {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }

  .right-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    .icon {
      height: 20px;
      width: 20px;
      path {
        fill: white;
      }
    }
    button {
      min-width: 240px;
      svg {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }

  .table {
    border-collapse: collapse;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-style: hidden;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1); /* this draws the table border  */

    tbody {
      tr {
        border: 1px solid rgba(0, 0, 0, 0.1);
        td {
          padding: 7px;
          &:first-child {
            background-color: #eb2701;
            width: 180px;
            p {
              color: white !important;
            }
          }
        }
      }
    }
  }
}

.eriko-heading {
  position: absolute;
}
