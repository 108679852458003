.card-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.segment {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .segment-heading {
    color: #02285e;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .segment-overview {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    .icon-container {
      display: flex;
      width: 50px;
      height: 50px;
      padding: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: #38b05a;
    }

    .overview {
      display: flex;
      flex-direction: column;
      gap: 5px;
      background: rgba(255, 229, 224, 0.5);
      // width: fit-content;
      width: 100%;
      border-radius: 5px;
      padding: 8px;
      p {
        line-height: 18px;
        font-weight: 700 !important;
        span {
          color: var(--Primary-Base-Black, #000);
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400 !important;
          line-height: 18px;
        }
      }
    }
  }

  .segments-statisitcs {
    .item {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000000;

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
      }
    }
  }
}

.divider {
  margin: 5px 0px;
  border: 1px solid rgba(56, 176, 90, 0.50);
}
