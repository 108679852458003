.container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: auto;
  z-index: 1000;
  top: 1%;
  left: 25%;

  min-width: 877px !important;

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px !important;

    .group-title {
      color: #eb2701;
      font-weight: 700;
      line-height: normal;
    }

    input {
      border-radius: 5px;
      border: 1px solid rgba(2, 40, 94, 0.1);
      background: #fff;
      padding: 10px;
       font-family: Inter, sans-serif;
    }

    textarea {
      border: 1px solid rgba(2, 40, 94, 0.1);
      border-radius: 5px;
      padding: 20px;
      resize: none;
       font-family: Inter, sans-serif;
    }

    .label {
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      span {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .radio-field {
      display: flex;
      gap: 10px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px;
  .title {
    color: #02285e;
    font-size: 32px;
    font-weight: 700;
    line-height: 20px;
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    display: flex;
    min-width: 95px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
}

.disclosure {
  border-radius: 5px;
  background: #fff;
  // box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05) !important;
  padding: 5px 0px;
  button {
    padding: 5px 20px !important;
    p {
      text-transform: capitalize !important;
      font-style: normal;
      font-weight: 700;
      line-height: 32px !important;
    }
  }
}

.closure-header {
  background: none !important;
  // padding: 7px 3px !important;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  background: #fff;
  padding: 0px;

  svg {
    path {
      fill: #02285e !important;
    }
  }
}

.closure-content {
  padding: 0px !important;
}

.open-closure-header {
  padding: 0px !important;
  svg {
    path {
      fill: #02285e !important;
    }
  }
}
