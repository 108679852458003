.customCalendarContainer {
  width: 100%;
  margin: 0 auto;

  width: 100% !important;
  height: 100%;
  overflow: scroll;

  [class^="react-calendar CalendarViewSchedules_customCalendar__SIigN"] {
    width: 100% !important;
    border: none;
    height: 70vh;
    overflow: hidden;
  }

  [class^="react-calendar__month-view__weekdays"] {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    [class^="react-calendar__month-view__weekdays__weekday"] {
      text-align: center;
      color: white;
      border-radius: 8px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #02285e;
    }
  }

  [class^="react-calendar__month-view__days"] {
    button {
      overflow: auto !important;
      min-width: fit-content;
      text-align: left;
      position: relative;

      border-width: 1px;
      border-style: solid;
      border-color: #e8e1d9;

      min-width: 200px;
      display: flex;
      flex-direction: column;

      &:disabled {
        border-style: solid;
        border-color: #e8e1d9;

        &:has(.customPostCard) {
          :disabled {
          }
        }
      }

      &:has(.customPostCard) {
        // background: #ffffff;
        border-width: 2px 2px 1px 2px;
        border-style: solid;
        padding: 5px;

        abbr {
          visibility: none;
          display: none;
        }
      }
    }
  }

  .react-calendar {
    width: 100%;
  }

  .react-calendar__tile {
    position: relative;
    padding: 10px;
    height: 150px; /* Adjust height as needed */
    background: #fff6eb;
    border: 1px dashed #ffd7a6;
    border-radius: 8px;
  }

  .react-calendar__tile--now {
    background: #f0f0f0;
  }

  .react-calendar__tile--active {
    background: #e6e6e6;
  }
}

.customCalendar {
  width: 100%;
}

.customTileContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-color: #eb2701;
}
