.container {
  display: flex;
  align-items: center;
  gap: 1rem;

  background: #ffffff;
  padding: 5px;
  width: 265px;

  // .color-picker {
  //   border-radius: 50%;
  //   width: 35px;
  //   height: 35px;
  //   background-color: #b58d56;
  //   border: none !important;
  //   outline: none !important;
  //   padding: 0px !important;
  //   -webkit-padding: 0px !important;
  //   overflow: hidden;
  // }

  .color-picker {
    background-color: red !important;
    border: none;
    height: 100px;
    // MuiColorInput-TextField
    [class*="MuiColorInput-Button"] {
      border: none !important;
      background-color: #ffffff;
    }
  }

  .color-value {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    // appearance: none !important;
    color: #000000;
    text-transform: uppercase;
  }
}

.color-picker-container {
  background: none !important;
  div {
    width: 100px !important;
    height: 100px !important;
    background: none !important;
    div {
      min-height: 100px !important;
      button {
        background: none !important;
        background: #ffffff;
        border: 2px dashed #778ba8;
        border-radius: 10px;
      }
    }
  }
  input {
    visibility: hidden;
  }
}
