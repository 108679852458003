/* Styling for the YesEdgeType */
.yesEdge {
  //   stroke: green;
  stroke-width: 1px;
  fill: none;
}

/* Styling for the NoEdgeType */
.noEdge {
  //   stroke: red;
  stroke-width: 1px;
  fill: none;
}

/* Styling for the edge label */
.edgeLabel {
  background-color: #ffffff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  //   white-space: nowrap;
  width: fit-content;
}

.noEdgeLabel {
  border-radius: 5px;
  background: #eb2701 !important;

  color: #fff;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
}

.yesEdgeLabel {
  display: inline-flex;
  padding: 3px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #38b05a;
  display: inline-flex;
  padding: 3px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
}
