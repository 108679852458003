@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;
@use "../../styles/mixins.scss" as m;

.text {
  color: c.$black;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 133.333% */
  margin: 0px;
  span {
    color: #eb2701;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  @include m.sm {
    font-size: 0.7rem;
    line-height: 1.2rem;
  }
}
