.container {
    
  padding: 1rem;

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #02285e;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 10px;
    padding-top: 10px;

    li {
      padding-left: 15px;
      position: relative;
      margin-bottom: 5px;

      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;

      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #eb2701;
      }

      &:before {
        content: "\2022"; /* Unicode for bullet */
        position: absolute;
        left: 0;
        color: #eb2701;
      }
    }
  }
}
