@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;
@use "../../styles/mixins.scss" as m;

.chat-container {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;

  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  z-index: 100;
  overflow: auto;
  background-color: #eb2701;
}

.container {
  width: 56%;
  display: flex;
  position: absolute;
  top: 0px;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden !important;
  height: 100%;
  padding-left: 4rem;

  .chat {
    padding: 2rem;
    padding-bottom: 80px !important;
    padding-right: 4rem;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1;
    overflow: auto;
  }
}

.input-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 47%;
  padding: 1rem;
  margin-bottom: 5px;

  background: #ffffff;
  border: 1px solid #eb2701;
  border-radius: 10px;

  position: fixed;
  bottom: 0px;
  left: 6.2rem;
  input {
    flex: 1;
    border: none;
    outline: none;
  }
}

.card {
  padding: 1rem;
  margin-left: 57px;
  border-radius: 10px;
  border-top-left-radius: 0px !important;
}

.chat-message {
  flex: 1 !important;
}

.white-background {
  background-color: #ffffff !important;
  color: #000000 !important;

  p {
    color: #000000 !important;
  }
}

.continue-button {
  min-width: 155px !important;
  height: 42px;

  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
  }
}

.next-actions-container {
  p {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }

  ol {
    margin-top: 10px !important;
    list-style-type: none;
    padding: 0;
    margin: 0;
    // showing 1, 2, 3,,

    li {
      counter-increment: my-awesome-counter;
      // margin-bottom: 1rem;
      margin-bottom: 2px;
      position: relative;
      padding-left: 2rem;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;

      &:before {
        content: counter(my-awesome-counter) ". ";
        position: absolute;
        left: 0;
        color: #ffffff;
      }
    }
  }
}
