.container {
  position: relative;
  flex: 1;
  width: fit-content !important;

  .response-content {
    position: relative;
    display: flex;
    gap: 0rem;
    align-items: self-end;
    width: 100% !important;
    height: 100% !important;
    z-index: 1;

    .content-container {
      border-radius: 20px;
      border: 3px solid #ffffff;
      padding: 1rem;
      flex: 1;
    }

    .theme-background {
      background: #02285E;
    }

    .white-background {
      background: #ffffff;
    }

    .white-svg {
      width: 40px;
      // height: 4em;
      margin-left: -3px;
    }

    .theme-svg {
      width: 40px;
      // height: 5em;
      margin-left: -4px;
    }

    .left-svg {
    }

    .right-svg {
    }
  }


  .response-content-shadow {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 0;

    display: flex;
    gap: 0rem;
    align-items: self-end;

    width: 100% !important;
    height: 100% !important;

    .content-container {
      border-radius: 20px;
      padding: 0.4rem 2rem;
      flex: 1;
      height: 100% !important;
    }

    .theme-background {
      background: #02285E;
      .svg {
        width: 5em;
        margin-left: -4px;
        path {
          fill: #02285E;
        }
      }
    }

    .white-background {
      background: #f2f2f2;
      .svg {
        width: 5em;
        height: 4em;
        margin-left: -3px;
        path {
          fill: #ffffff;
        }
      }
    }

    .white-svg {
      width: 40px;
      // height: 6em;
      margin-left: -11px;
    }

    .theme-svg {
      width: 40px;
      // height: 5em;
      margin-left: -11px;
    }

    .left-svg {
    }

    .right-svg {
    }
  }


  .left-direction {
    flex-direction: row-reverse;
  }

  .right-direction {
    flex-direction: row;
  }
}
