.header-text {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;

  button {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 310 !important;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
  }
}

.filter-container {
  padding-top: 2rem;
  display: flex;
  gap: 2rem;
  position: relative;

  .delete-btn {
    display: flex;
    gap: 1rem;
    align-items: center;
    &:hover {
      color: #eb2701;
      svg {
        path {
          fill: #eb2701 !important;
        }
      }
    }
  }
}

.table-wrapper {
  padding-right: 20px;
  margin-bottom: 40px;

  thead {
    tr {
      th {
        // not the first child
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        // padding-left: 10px;
        // margin-right: 20px;
        color: #02285e;

        &:nth-child(2),
        &:nth-child(1) {
          text-align: left !important;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:last-child {
          width: 10%;
        }
        text-align: center;
        &:nth-child(2),
        &:nth-child(1) {
          text-align: left !important;
        }
      }
    }
  }

  .outreach {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #02285e;
  }

  .closing {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #38b05a;
  }
}

.table-text {
  color: red !important;
}

.table-row {
  // cursor: pointer;
  a {
    &:hover {
      color: #eb2701;
    }
  }
}

.status-container {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.new-campaign-btn {
  min-width: 200px;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 1%;

  border-radius: 10px;
  border: 2px solid #eb2701 !important;
  background: #fff;

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 40px;
    color: #eb2701;
  }

  &:hover {
    p {
      color: #ffffff;
    }

    svg {
      path {
        fill: #ffffff;
      }
    }
  }
}
