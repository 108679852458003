.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
  padding-top: 2rem;

  .competitors {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    .card {
      background: #ffffff;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding-bottom: 0px;
      width: 35%;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
  }

  .navigation {
    display: flex;
    align-self: flex-end;
    gap: 5px;
    button {
      min-width: 20px;
      max-height: 20px;
      width: 20px;
      height: 20px;
      border-radius: 5px;
    }
  }
}

.pin-icon{
  top: 0.5rem !important;
  right: 0.5rem !important;
}