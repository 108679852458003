.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  border-radius: 10px;
  background: #fff;
  box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.05);
  position: relative;

  padding: 10px;

  .content {
    display: flex;
    gap: 1rem;

    .value-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      justify-content: flex-end;
      width: 70%;

      .value {
        color: #02285e;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 100% */
      }

      .label {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 360 !important;
        line-height: 20px; /* 125% */
      }
    }
  }
}

.info-icon {
  position: absolute;
  top: 5px;
  right: 8px;
  cursor: pointer;
}
