.container {
  display: flex;
  width: 100%;
  gap: 1px;
  height: 100%;
}

.tabs {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;

  align-items: flex-start;
  padding: 5px;
  gap: 5px;

  width: 50px;
  height: fit-content;

  background: #ffffff;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: rgba(163, 179, 201, 0.2);

  .tab {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;

    width: 40px;
    height: 40px;

    background: rgba(235, 39, 1, 0.1);
    border-radius: 5px;

    svg {
      path {
        fill: #eb2701;
      }
    }
  }

  .active-tab {
    background: #eb2701;
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.tab-content {
  display: block;
  background-color: #fff;
  width: 100%;
}
