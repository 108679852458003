.sidebar {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    height: 100vh;
    background: #ffffff;
    // box-shadow: 0px 0 5px rgba(0, 0, 0, 0.1);
    // padding: 20px;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .content {
    // flex: 1;
    // overflow-y: auto;
    margin-top: 20px;
  }
  
  .field {
    margin-bottom: 20px;
  }
  
  .field label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  
  .field input[type="text"] {
    width: 90%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .saveButton,
  .cancelButton {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .saveButton {
    background-color: #4caf50;
    color: white;
  }
  
  .cancelButton {
    background-color: #f44336;
    color: white;
  }
  

  .deleteButton {
    margin-left: 8px;
    color: red;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .inputWithDelete {
    display: flex;
    align-items: center;
  }
  
  .inputWithDelete input {
    flex-grow: 1;
  }
  