@use "../../../../styles/mixins.scss" as m;

.container {
  display: flex;
  gap: 0.5rem;

  .content {
    max-width: 100%;
    height: fit-content;
    flex-shrink: 0;
    padding-right: 10%;
    flex: 1;
  }

  align-items: flex-end;
  
  &.icon-right {
    flex-direction: row-reverse;
    align-items: flex-end;
  }

  &.icon-left {
    flex-direction: row;
    align-items: end;
  }

  &.icon-none {
    flex-direction: row;
    align-items: end;
  }

  .ninja-icon,
  .user-icon,
  .fast-ninja-icon {
    max-height: 40px;
    flex-shrink: 0;

  }

  .fast-ninja-icon {
    max-height: 90px !important;
    width: 60px !important;
  }
}
