.container {
  position: relative;
}

.ellipses {
  font-size: 1rem;
  font-weight: 900;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  transform: rotate(90deg);
  color: rgba(0, 0, 0, 0.7);
}

.items {
  position: absolute;
  top: 120%;
  right: 90%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: fit-content;

  .item {
    background: #ffffff;
    border: 1px solid rgba(2, 40, 94, 0.1);
    width: 120px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #000000;

    cursor: pointer;
    &:hover {
      background: #02285e;
      color: white;
    }
  }
}
