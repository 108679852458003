@use "../../styles/colors.scss" as c;

.form {
  text-align: center;

  .pin-title {
    color: c.$mid-night-blue;
    font-weight: bold;
    font-size: 32px;
  }

  .pin-body {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .pin-digit-input {
    input[type="text"] {
      display: inline;
      font-size: 40px;
      margin: 10px;
      width: 60px;
      height: 80px;
      border-radius: 5px;
      text-align: center;
      border: c.$bright-red solid 1px;
      outline: none;

      &:focus {
        border: c.$mid-night-blue solid 1px;
      }
    }
  }

  .submit {
    height: 40px;
    width: 200px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: unset !important;
  }
}


.error{
  color: c.$bright-red;
  font-size: 16px;
  margin-top: 10px;
}