.container {
  height: 100vh;
  position: relative;

  .content {
    background: #ffffff;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    position: absolute;
    top: 15%;
    left: 20%;
    width: 700px;
    height: 600px;

    padding-top: 50px;

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 50px !important;

      .ninja-icon {
        width: 79px;
        height: 67px;
      }

      .ninja-text {
        width: 158px;
        height: 27px;
      }
    }
  }

  .sideNinja {
    height: 100%;
    position: absolute;
    right: 0px !important;
    z-index: 2;
  }
}
