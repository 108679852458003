@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0px;
  background-color: #FFF6EB;
  min-height: 100vh;
  #root {
    width: 100%;
    max-height: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    background-color: #FFF6EB;
    position: relative;
  }

  // .react-flow__edge-textwrapper {
  //   display: inline-block;
  //   padding: 6px 16px;
  //   background-color: #34495e; /* Dark gray background */
  //   border-radius: 18px;
  //   color: #ecf0f1; /* Light text color */
  //   border: 2px solid #9b59b6; /* Neon purple border */
  //   box-shadow: 0 0 10px rgba(155, 89, 182, 0.4); /* Purple neon glow effect */
  //   transition: all 0.3s ease;
  // }
  
  
  // .react-flow__edge-text {
  //   font-size: 16px;
  //   color: #fff; /* White text */
  //   font-weight: bold;
  //   text-shadow: 0 0 4px rgba(255, 255, 255, 0.6); /* Soft text glow */
  // }
  
  
  .react-flow__controls{
    margin: 50px;
  }
  
  
}

* {
  box-sizing: border-box;
}
