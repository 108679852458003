.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #02285e;
  }

  .content-container {
    display: flex;
    gap: 1rem;
    .icon-container {
      padding: 20px;
      width: 80px;
      height: 80px;

      background: #ffe5e0;
      border-radius: 10px;
    }
    ul {
      list-style-type: disc;
      margin-left: 20px;

      li {
        list-style: disc;
        font-size: 16px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }

    .ul-with-heading {
      ul {
        margin-left: 50px;
      }
    }
  }
}
