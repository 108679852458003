// MIN-WIDTH BREAKPOINTS
//to be used on @media(min-width:<<breakpoint>>){}
$min-breakpoint-xs: 0;
$min-breakpoint-sm: 576px;
$min-breakpoint-md: 768px;
$min-breakpoint-lg: 992px;
$min-breakpoint-xl: 1200px;
$min-breakpoint-xxl: 1600px;

// MAX-WIDTH BREAKPOINTS
//to be used on @media(max-width:<<breakpoint>>){}
$max-breakpoint-xs: 0;
$max-breakpoint-sm: 575px;
$max-breakpoint-md: 767px;
$max-breakpoint-lg: 991px;
$max-breakpoint-xl: 1199px;
$max-breakpoint-xxl: 1599px;

// Small devices
@mixin sm {
  @media (max-width: #{$min-breakpoint-sm}) {
    @content;
  }
}

@mixin min-sm {
  @media (min-width: #{$min-breakpoint-sm}) {
    @content;
  }
}


// Medium devices
@mixin md {
  @media (min-width: #{$min-breakpoint-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$min-breakpoint-lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$min-breakpoint-xl}) {
    @content;
  }
}
