.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 35px;

  background: #02285e;
  border-radius: 10px;

  font-weight: 700;
  font-size: 16px;
  line-height: 32px;

  color: #ffffff;
}
