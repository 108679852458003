.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    color: #02285e;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    

    ul {
      list-style-type: disc;
      margin-left: 20px;

      li {
        list-style: disc;
        padding-bottom: 10px;
        font-size: 16px;
        font-family: "Inter", sans-serif;
      }
    }

    .highlight {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }

    .ul-with-heading {
      ul {
        margin-left: 50px;
      }
    }
  }
}

.sub-heading-text {
  color: #eb2701;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;
}
