@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;
@use "../../styles/mixins.scss" as m;

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.hot-insight {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4em !important;
  line-height: 1.4em !important;
  color: #ffffff !important;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1.4rem;
  flex-direction: column;
  div {
    cursor: pointer !important;
    touch-action: none;
  }
  .grid-item {
    cursor: pointer !important;
  }
}

.heading {
  text-transform: uppercase;
  color: #eb2701;
  font-family: "Inter", sans-serif;
}

.heading-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.marketing-image {
  width: 637px;
  height: 100%;
}

.marketing-video {
  width: 637px;
  height: 100%;
}

.banner-wrapper {
  width: 100%;
}

.eriko-heading {
  position: absolute;
  padding: 2.3rem;
  img {
    height: 40px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cmo-filters {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.key-findings {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(1, 25, 60, 0.05);
  border-radius: 20px;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #02285e;
  }

  .findings {
    display: flex !important;
    gap: 1rem;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.iconic-container {
  max-width: 100%;
  min-width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  .content {
    padding: 1rem;
    border-radius: 10px;
    border-top-left-radius: 0px;
    flex: 1;
    background: #ffffff !important;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  }

  svg {
    max-width: 65px;
  }
}

.full-width-element {
  width: 100% !important;
}

.two-column {
  width: 100%;
  display: grid;
  gap: 1rem;

  display: flex;
  justify-content: space-between;

  div {
    flex: 1;
  }

  .doughnut-chart {
    width: 100% !important;
    height: 100% !important;
    canvas {
      width: 100% !important;
      height: 100% !important;
      padding: 0px !important;
    }
  }
}

.three-column {
  width: 100%;
  gap: 1rem;

  display: flex;
  justify-content: space-between;

  div {
    flex: 1;
  }

  .pie-chart-mini {
    canvas {
      width: 100% !important;
      height: 100% !important;
      padding: 0px !important;
    }
  }

  .pie-chart {
    canvas {
      width: 100% !important;
      height: 100% !important;
      padding: 0px !important;
    }
  }
}

.four-column {
  width: 100%;
  gap: 1rem;

  display: flex;
  justify-content: space-between;

  div {
    flex: 1;
  }

  .pie-chart-mini {
    // width: 100% !important;
    // height: 100% !important;
    canvas {
      width: 100% !important;
      height: 100% !important;
      padding: 0px !important;
    }
  }

  .pie-chart {
    // width: 100% !important;
    // height: 100% !important;
    canvas {
      width: 100% !important;
      height: 100% !important;
      padding: 0px !important;
    }
  }
}

.report-heading {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: -18px;
  color: #02285e;
}

.header-action-btn {
  height: 40px;
  padding: 4px 1.5rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.graph-container {
  display: flex !important;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 20px !important;

  .graph {
    width: 100% !important;
    height: 100% !important;
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .graph-title {
    align-self: flex-start !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 32px;
    color: #02285e;
  }

  .pie-chart {
    width: 60% !important;
    height: 100% !important;
    canvas {
      width: 100% !important;
      height: 100% !important;
      padding: 0px !important;
    }
  }
}

.line-container {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-end !important;

  .line-graph {
    width: 100% !important;
    height: 80% !important;
    canvas {
      width: 100% !important;
      height: 70% !important;
    }
  }
}

.svg-container {
  display: flex !important;
  // align-items: flex-start !important;
  p {
    margin-left: -500px;
    

    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */

    color: #02285e;
  }
  svg {
    width: 90%;
    height: 90%;
  }
}

.strategy-card {
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  padding: 1rem;
  // align-items: center;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  // flex: 0 !important;

  .header {
    display: flex !important;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    // height: fit-content !important;
    flex: 0 !important;
    min-height: 60px !important;
    p {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #02285e;
    }
  }

  .content {
    align-self: flex-start !important;

    font-weight: 340;
    padding-left: 15px;
    // padding: 1rem 10px;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    color: #000000;
  }
}
