.campaign-builder-layout {
  overflow: hidden !important;
  height: 100%;
}

.container {
  overflow: auto !important;
  height: 100%;
  position: relative;

  .header {
    height: 80px;
    z-index: 105;
    align-self: flex-start;
    position: fixed;
  }

  .content {
    position: relative;
    display: flex;
    overflow: hidden;
    margin-top: 80px;
    gap: 1rem;
    height: 91vh;
  }

  .side-bar {
    height: 100% !important;
    position: sticky;
  }
}

.mql-insights {
  width: 100%;
  overflow: auto;
  // margin-left: 90px;
  // padding: 1em;
  padding: 1rem;
  padding-left: 0px;
  margin-right: -15px;
  padding-right: 2rem;
}
