@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;
@use "../../styles/mixins.scss" as m;

.container {
  position: relative;
  border-radius: 10px;
  border: 1px solid rgba(163, 179, 201, 0.2);
  background: #fff;
  padding: 10px;

  .chart {
    object-fit: contain;
    min-height: 433px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .title {
    width: 100%;
    color: #02285e;
    font-size: 20px;
    font-style: normal;
    font-weight: 550 !important;
    line-height: 23px;
    text-align: left !important;
  }
}
