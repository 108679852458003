@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;
@use "../../styles/mixins.scss" as m;

.chat-container {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;

  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  z-index: 100;
  overflow: auto;
  background-color: #eb2701;
}

.container {
  width: 100%;
  display: flex;
  position: absolute;
  top: 0px;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden !important;
  padding-bottom: 200px !important;
  padding-right: 2rem;

  .chat {
    padding: 2rem;
    padding-bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1;
    overflow: auto;
  }
}

.input-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 48%;
  padding: 1rem;
  margin-bottom: 5px;

  background: #ffffff;
  border: 1px solid #eb2701;
  border-radius: 10px;

  position: fixed;
  bottom: 0px;
  input {
    flex: 1;
    border: none;
    outline: none;
  }
}

.card {
  padding: 1rem;
  margin-left: 57px;
  border-radius: 10px;
  border-top-left-radius: 0px !important;
}

.chat-message {
  flex: 1 !important;
}

.white-background {
  background-color: #ffffff !important;
  color: #000000 !important;

  p {
    color: #000000 !important;
  }
}

.continue-button {
  min-width: 155px !important;
  height: 42px;

  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
  }
}

.connect-channels-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 0px !important;
  gap: 1rem;
  button {
    min-width: 300px !important;
    padding: 1rem !important;
  }
}
