.header-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #fff;
}

.actions-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-top: 30px;

  .search-input {
    width: 312px;
  }

  .add-member-btn {
    float: right;
    height: 40px;
    font-weight: unset !important;
    font-size: 12px !important;
  }
}
