.form-container {
  width: 80%;
  margin: 0px auto;
  margin-top: -20px;
  .form {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;

    .submit {
      height: 46px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
  }
}

.error {
  color: red;
  font-size: 14px;
}

.underline-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #02285e;
  cursor: pointer;
}
