.tab__list {
  display: flex;

  .item {
    padding: 5px 30px;
    gap: 5px;
    background: #eceef1;
    border-radius: 10px 10px 0px 0px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    .counter {
      height: 23px;
      width: 23px;
      border-radius: 50%;
      background: #778ba8;
    }

    .label {
      line-height: 44px;
      color: #778ba8;
    }

    &--active {
      @extend .item;
      background: #02285e;

      .counter {
        @extend .counter;
        background: #ffffff;
      }

      .label {
        @extend .label;
        color: #ffffff;
      }
    }
  }
}

.panel {
  width: 99%;
}