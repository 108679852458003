.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 1rem;

  .heading-container{
    display: flex; 
    gap: 10px;
  }

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #02285e;
  }

  .content-container {
    display: flex;
    gap: 1rem;
  }
}
