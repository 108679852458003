.container {
  width: 100%;
  padding: 8px 10px;
  .bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eceef1;
    border: none;
    outline: none;
    padding: 1rem 2rem;
    p {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;

      text-transform: uppercase;

      color: #02285e;
    }

    svg {
      path {
        fill: #02285e;
      }
    }

    &.open {
      background-color: #02285e;

      p {
        color: #eceef1;
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}
