.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #02285e;
  }

  .offering {
    display: flex;
    gap: 1rem;
    align-items: flex-start;

    svg {
      width: 280px !important;
      height: 50px;
    }
  }
}
