.post-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 5px;

  // width: 290px;
  // height: 241px;
  background: #fff6eb;
  border: 1px dashed #ffd7a6;
  border-radius: 8px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
      z-index: 50;
    }
  }
  .content {
    width: 100%;
    display: flex;
    // gap: 1rem;
    gap: 5px;
    img {
      width: 25px;
      height: 25px;
    }
    p {
      max-width: 165px;
      flex: 1;

      text-align: left;
      // max-height: fit-content; /* Adjust the max height as needed */
      overflow: hidden;
      font-size: 14px;
      line-height: 15px;
      text-overflow: ellipsis;
      // word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* Number of lines to show */
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
}

.value-proposition {
  display: block;
  text-align: center;

  padding: 5px 10px;
  // height: 30px;

  background: #287be7;
  border-radius: 10px;
  text-align: center;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  // text-transform: uppercase;
  color: #ffffff;
}
