.comment-side-bar {
  width: 500px;
  height: 82vh;
  position: fixed;
  right: 0px;
  top: 18%;
  bottom: 0px;
  transition: right 0.3s ease;
  z-index: 10;

  display: flex;
  flex-direction: column;

  background: #eceef1 !important;
  box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0px 0px;

  gap: 1rem;

  .header {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;

    background: #eb2701;
    border-radius: 10px 10px 0px 0px;

    .title {
      flex: 1;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffff;
    }

    .closeButton {
      cursor: pointer;
      height: 13.5px;
      width: 13.5px;
    }
  }

  .comment-list {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    padding: 1rem;
    overflow-y: auto;
  }

  &.open {
    right: 0;
  }
}

.comment-point {
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  height: max-content;
}

.reply-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 5px;

  .comment-input {
    background: #ffffff;
    border: 1px solid #e9ebf0;
    border-radius: 8px;
    resize: none;
    padding: 10px;
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      cursor: pointer;
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }
    .action-btn {
      min-width: 100px;
      align-self: flex-end;
      height: 40px;
    }
  }
}
