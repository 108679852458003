.container {
  // width: 300px;
  position: relative;
  font-family: Arial, sans-serif;
}

.textInput {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 4px;
  margin-top: 5px;
  z-index: 3;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checkboxItem {
  padding: 0px 10px;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
//   z-index: 3
  background: #ffffff;
  border: 1px solid rgba(2, 40, 94, 0.1);
  label {
    padding: 5px 17px;
    width: 100%;
    height: 100%;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #000000;
  }

  &:hover {
    background: #ffe5e0;
    border: 1px solid rgba(2, 40, 94, 0.1);
  }
}

.checkboxItem label {
  cursor: pointer;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.customCheckbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  user-select: none;
}

.customCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkboxMark {
  position: absolute;
  top: 7px;
  left: -7px;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 4px;
  transition: 0.3s;
}

.customCheckbox input:checked + .checkboxMark {
  background-color: #eb2701;
  border-color: #eb2701;
}

.checkboxMark::after {
  content: "";
  position: absolute;
  display: none;
}

.customCheckbox input:checked + .checkboxMark::after {
  display: block;
  left: 5px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
