.projection-card {
  display: flex;
  flex-direction: column;
  gap: 10px;

  border-radius: 10px;

  .industry {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #02285e;
  }

  .rate {
    font-weight: 700;
    font-size: 28px;
    // line-height: 24px;
    color: #eb2701;
  }

  .description {
    span {
      font-weight: 700;

      color: #000000;
    }
  }
}
