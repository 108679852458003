.container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  scroll-margin-top: 10px;

  .icon {
    width: 45px !important;
  }

  .user-icon{
    width: 45px !important;
    height: 45px !important;
  }

  .message-container {
    background: #02285e;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border-top-left-radius: 0px;
    padding: 1rem;

    .message {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;

      color: #ffffff;
    }
  }
}
