.container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
  margin-left: -50px;
}

.body {
  display: flex;
  gap: 1px;
  flex-direction: row;
  overflow: hidden;
  z-index: 20;

  .vertical-tabs {
    width: 100%;
    height: 100%;
  }
}

.content-expanded {
  .vertical-tabs {
    width: 365px;
  }

  .reactflow-wrapper {
    flex: 1;
  }
}

.content-collapsed {
  .vertical-tabs {
    max-width: 50px;
  }

  .reactflow-wrapper {
    flex: 1;
  }
}
