.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 6rem;
  background-color: #fff;
  padding: 1rem;
}

.campaign-data {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(50%, 1fr));
  gap: 1rem;
}

.market-sentiments{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.competitor-analysis{
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.recommendation-data {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr)) !important;
  gap: 1rem;
}

.disclosure-footer {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-left: -10px;
  .underline-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-align: right;
    text-decoration-line: underline;
    cursor: pointer;
    color: #02285e;
  }
}

.audience-segmentation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .audience-segmentation-card {
    width: 100%;
  }
}
