.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #02285e;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .follow-item {
      display: flex;
      align-items: flex-start;
      gap: 7px;

      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffe5e0;
        border-radius: 5px;
        padding: 3px;

        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }

      .text {
        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #02285e;
        }

        ul {
          list-style-type: disc;
          margin-left: 20px;
    
          li {
            list-style: disc;
            font-size: 16px;
            font-family: "Inter", sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
        }
      }
    }
  }
}
