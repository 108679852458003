.container {
  padding: 1rem;
  background: #ffffff;
  border-radius: 10px;
  //   width: 700px;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      color: #02285e;
    }

    .edit-icon {
      cursor: pointer;
    }
  }

  .plans-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .plan {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .field {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .label {
          font-weight: 600;
          font-size: 16px;

          text-transform: capitalize;
          color: #eb2701;

          font-style: normal;

          line-height: 18px;
        }
        width: 100%;
      }
      .divider {
        width: 100%;
        border: 1px solid rgba(235, 39, 1, 0.5);
      }
    }

    .update-button {
      min-width: 100px;
      max-width: 100px;
      align-self: flex-end;
    }
  }
}

.select-button {
  /* mplatform-blog */

  background: #ffffff;
  border: 1px solid rgba(2, 40, 94, 0.1) !important;
  svg {
    path {
      fill: rgba(2, 40, 94, 0.1) !important;
    }
    // fill: #02285e !important;
  }
}

.option {
  border: none;
}

.two-fields {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.disabled-input {
  input {
    background: #e7edf7;
    border: 1px solid rgba(2, 40, 94, 0.1);
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 30px !important;
    color: #778ba8 !important;
  }
}
