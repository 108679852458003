.container {
  padding: 30px;

  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .header {
    .title {
      display: flex;
      align-items: center;  
      gap: 5px;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: #02285e;
    }
  }

  .offers {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));

    gap: 1rem;

    .offer {
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      background: #ffffff;
      border: 2px solid #eb2701;
      border-radius: 10px;
      gap: 10px;
      padding: 18px;

      .icon-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 10px;
        width: 30px;
        height: 30px;
        background: #eb2701;
        border-radius: 25px;
      }

      .text {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 350;
        font-size: 16px;
        line-height: 20px;
        color: #000000;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: #02285e;
        }
      }

      &:hover {
        background-color: #ffe5e0;
        .icon-container {
          background: #eb2701;
          border-radius: 25px;
        }
      }
    }
  }

  .loading {
    align-self: center;
    width: 356px;
    height: 313px;
  }
}
