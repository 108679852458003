.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  border-radius: 10px;
  border: 1px solid #fcd4a3;
  background: #fff6eb;

  position: relative;

  padding: 10px;

  .content {
    display: flex;
    gap: 1rem;

    .icon-container {
      display: flex;
      width: 140px;
      height: 120px;
      padding: 5px !important;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: #fff;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    .value-container {
      display: flex;
      flex-direction: column;

      justify-content: flex-end;

      .value {
        color: #02285e;
        font-family: Inter;
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
        line-height: 90px; /* 100% */
      }

      .label {
        color: var(--Primary-Base-Black, #000);
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px; /* 125% */
      }
    }
  }

  .footer {
    display: flex;
    gap: 1rem;

    .create-btn {
      max-width: fit-content;
      color: #fff;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      padding: 8px !important;
    }

    .disable-btn-text {
      display: none;
      color: #eb2701;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 166.667% */
    }

    &:hover {
      .disable-btn-text {
        display: block;
      }
    }
  }

  .warning-text {
    color: #eb2701;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.info-icon {
  position: absolute;
  top: 5px;
  right: 8px;
  cursor: pointer;
}

.clickable {
  line-height: 20px; /* 125% */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  cursor: pointer;
}

