.content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 10rem;
}

.closure-header {
  background: none !important;
  padding: 7px 3px !important;

  text-transform: lowercase !important;
  border-bottom: 2px solid #eb2701 !important;
  p {
    text-transform: capitalize !important;
    font-weight: 450 !important;
    font-size: 20px;
    color: #02285e;
  }
}

.closure-content {
}

.open-closure-header {
  p {
    color: #02285e;
    text-transform: capitalize !important;
  }
  svg {
    path {
      fill: #02285e;
    }
  }
}

.loader-container {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 2rem;
  padding: 10px;
}

.loader {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #eb2701, -20px 0 rgba(235, 39, 1, 0.6);
    background: rgba(235, 39, 1, 0.3);
  }
  33% {
    box-shadow: 20px 0 #eb2701, -20px 0 rgba(235, 39, 1, 0.6);
    background: rgba(235, 39, 1, 0.6);
  }
  66% {
    box-shadow: 20px 0 rgba(235, 39, 1, 0.6), -20px 0 #eb2701;
    background: rgba(235, 39, 1, 0.3);
  }
  100% {
    box-shadow: 20px 0 rgba(235, 39, 1, 0.3), -20px 0 #eb2701;
    background: #eb2701;
  }
}

.tab-content {
  padding: 1rem;
}

.past-insights {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem 0rem;
}

.generate-buttons-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0rem 10px;
  .generate-button {
    max-width: fit-content;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
}

.audience-segmentation {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 2fr));
  gap: 1rem;
}

.market-sentiments {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 2fr));
  gap: 1rem;
}

.recommendations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content-calendar {
}

.audience-segmentation-card{
  padding: 1rem;
}

.swot-analysis{
  padding: 1rem 5px;
}

.performance-metrics-card{
  padding: 1rem 5px;
}
.card{
  padding: 10px 1rem;

}