.plans-container {
  display: flex;
  gap: 1rem;
  padding: 2rem 0rem;

  .plans {
    display: flex; 
    flex-direction: column;
    width: 100%;

    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.05));

    .plan {
      padding: 15px;
      background: #ffffff;
      border-width: 1px 1px 1px 1px;
      border-style: solid;
      border-color: #e4e9ef;
      cursor: pointer;

      .plan-header {
        display: flex;
        align-items: center;
        gap: 1rem;

        .plan-title {
          font-weight: 400 !important;
          line-height: 24px;
          margin-left: -9px;
        }

        .arrow {
          margin-left: auto;
          transition: transform 0.6s ease-in-out;
        }
      }

      &.active {
        background: #ffe5e0;
        border: 1px solid #ffe5e0;
        .plan-header {
          .arrow {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.plan-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;

  // width: 614px;
  min-width: 50%;

  border: 2px solid #eb2701;
  border-radius: 10px;

  .header {
    display: flex;
    gap: 8px;
    align-items: first baseline;
    p {
      color: #000;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; 
    }
  }

  .item {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .label {
      font-weight: 700 !important;
      font-size: 16px;
      line-height: 24px;
      color: #02285e;
    }

    .value {
      background: rgba(255, 229, 224, 0.3);
      border-radius: 5px;

      padding: 10px;

      ul {
        list-style-type: disc;
        //   margin-top: 5px;
        margin-left: 20px;

        li {
          color: var(--Primary-Base-Black, #000);
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 340 !important;

          line-height: 24px; /* 150% */
          span {
            font-weight: 700;
          }
          list-style: disc;
          // padding-bottom: 10px;
          font-size: 16px;
          font-family: "Inter", sans-serif;
        }
      }
    }
  }
}
