.container {
  padding: 10px;
  background: #ffffff;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;

  .meta-info {
    display: flex;
    gap: 1rem;
    align-items: center;

    .profile-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .username {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #334253;
    }

    .time {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #778ba8;
    }
  }

  .comment {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #67727e;
  }

  .reply-button {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      color: #02285e;
    }
  }
}

.comment-reply {
  background: #ffffff;

  background: #ffffff;
  border-radius: 8px;
}

