.suggested-prompt {
  border: none;
  cursor: pointer;

  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  // height: 78px;

  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  .icon {
    max-height: 20px !important;
    min-height: 20px !important;
    max-width: 30px !important;
    min-width: 30px !important;
    align-self: flex-start;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    overflow: hidden;

    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      white-space: nowrap;
      overflow: ellipse;
      color: #eb2701;
    }

    .description {
      flex: 1;
      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
}

.suggested-prompt:hover {
  background: #eceef7;
}
