.container {
  font-family: Arial, sans-serif;
  display: flex;
  align-items: first end;
  gap: 2rem;
  width: 100%;

  .bar-container {
    flex: 0.99;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.score {
  color: #02285e;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
}

.bar {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  background-color: #f0f4f8;
}

.section {
  flex: 1; /* Each section takes up an equal part */
  height: 100%;
  border-right: 1px dashed #000;
}

.red {
  border-right: 1px dashed var(--Primary-Base-Black, #000);
  border-left: 1px dashed var(--Primary-Base-Black, #000);
  background: #eb2701;
}

.yellow {
  border-right: 1px dashed var(--Primary-Base-Black, #000);
  border-left: 1px dashed var(--Primary-Base-Black, #000);
  background: rgba(235, 141, 1, 0.8);
}

.green {
  border-right: 1px dashed var(--Primary-Base-Black, #000);
  border-left: 1px dashed var(--Primary-Base-Black, #000);
  background: rgba(56, 176, 90, 0.8);
}

.marker {
  position: absolute;
  top: -8px;
  height: 48px;
  transition: left 0.3s ease-in-out;
  border-radius: 5px;
  border: 2px solid var(--Primary-Base-Black, #000);
}

.icons {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.icon {
  font-size: 1.2rem;
}
