.container {
  position: relative;
  flex: 1;
  width: fit-content !important;

  .response-content {
    position: relative;
    display: flex;
    gap: 0rem;
    align-items: self-end;
    width: 100% !important;
    height: 100% !important;
    z-index: 1;

    .content-container {
      border-radius: 20px;
      border: 3px solid #ffffff;
      padding: 1rem 1rem;
      flex: 1;
      position: relative;
      .ellipses {
        position: absolute;
        top: -1px;
        right: 12px;
      }
    }

    .theme-background {
      background: #eb2701;
    }

    .white-background {
      background: #ffffff;
    }

    .white-svg {
      width: 40px;
      margin-left: -3px;
    }

    .theme-svg {
      width: 40px;
      margin-left: -4px;
    }
  }

  .response-content-shadow {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 0;

    display: flex;
    gap: 0rem;
    align-items: self-end;

    width: 100% !important;
    height: 100% !important;

    .content-container {
      border-radius: 20px;
      padding: 0.4rem 2rem;
      flex: 1;
      height: 100% !important;
    }

    .theme-background {
      background: #c82202;
      .svg {
        width: 5em;
        margin-left: -4px;
        path {
          fill: #c82202;
        }
      }
    }

    .white-background {
      background: #f2f2f2;
      .svg {
        width: 5em;
        height: 4em;
        margin-left: -3px;
        path {
          fill: #ffffff;
        }
      }
    }

    .white-svg {
      width: 40px;
      margin-left: -11px;
    }

    .theme-svg {
      width: 40px;
      margin-left: -11px;
    }
  }

  .left-direction {
    flex-direction: row-reverse;
  }

  .right-direction {
    flex-direction: row;
  }
}

.response-wrapper {
  display: flex;
  gap: 0.5rem;

  .content {
    max-width: 100%;
    height: fit-content;
    flex-shrink: 0;
    // padding: 1rem;
    padding-right: 10%;
    flex: 1;
  }

  align-items: flex-end;
  &.icon-right {
    flex-direction: row-reverse;
    align-items: flex-end;
  }

  &.icon-left {
    flex-direction: row;
    align-items: flex-end;
  }

  &.icon-none {
    flex-direction: row;
    align-items: flex-end;
  }

  .ninja-icon,
  .user-icon,
  .fast-ninja-icon {
    max-height: 40px;
    flex-shrink: 0;
  }

  .fast-ninja-icon {
    height: 40px !important;
    width: 40px !important;
  }
}

.skeleton-loading {
  width: 100% !important;
  height: 200px;
  display: block;
}

.insight-response {
  line-height: 32px;
  font-family: "Inter",sans-serif;
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}
