.container-wrapper {
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  height: 100%;
  background: #fff6eb;
  width: 60%;
  margin: 14% auto;

  .chat-input {
    height: 60px;
    input {
      line-height: 24px !important;
    }

    .chat-button {
      min-width: 150px;
      .stars-icon {
        height: 20px;
        width: 20px;
        path {
          fill: #fff;
        }
      }

      .text {
        font-weight: 700;
      }
    }
  }

  .suggested-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .actions {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, 50%);

      .action {
        font-weight: 400;
        text-align: justify;
        color: #000000;
      }
    }
  }
}

.ninja-icon {
  max-height: 100px !important;
  height: 100px !important;
  width: 120px;
}

.custom-insights {
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: #000000;

  span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #eb2701;
  }
}
