.container {
  // padding: 1rem;
  .company-info {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row !important;
    align-items: center;
    padding-top: 5px;
    padding: 20px;
    background: #fff6eb;
    border-radius: 10px 10px 0px 0px;
    min-height: 80px;

    .company-meta-info {
      display: flex;
      flex-direction: column;
      align-self: flex-start;

      text-align: start;

      .counter {
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        text-transform: uppercase;

        color: #eb2701;
      }

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }

      .company-name {
        font-weight: 700 !important;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 1rem;

    li {
      // padding-left: 15px;
      position: relative;
      margin-bottom: 5px;

      .header-container {
        display: flex;
        gap: 8px;
        align-items: center;
        p {
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          line-height: 30px;
          color: #02285e;
        }

        svg{
          height: 17px;
          width: 17px;
        }
      }

      .text {
        span {
          // display: block;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          width: fit-content;
          white-space: nowrap; /* Prevent heading from breaking */
        }
      }

      &:before {
        content: ""; /* Unicode for bullet */
        position: absolute;
        left: 0;
        color: #333; /* Color of the bullet */
      }
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
