.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  .input {
    width: 100%;
    height: 40px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    padding: 0.5rem;
    font-size: 1rem;

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: #000000;

    &::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.5);
    }

    &:focus {
      outline: 1px solid #eb2701;
      border: 1px solid #eb2701;
    }
  }

  .input-skeleton {
    height: 40px !important;
    border-radius: 10px;
  }

  label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #eb2701;
  }

  .error {
    color: red;
    font-size: 0.8rem;
  }
}
