.input-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;

  label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: block;
    color: #02285e;
  }

  .input-container {
    width: 100%;
    position: relative;
    border: 1px solid #eb2701;
    border-radius: 5px;

    input {
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 5px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-size: 16px;
      border: none;
      outline: none;
      &:focus {
        border-color: #02285e;
        outline: none;
      }
    }

 
  }

  .password-input-container {
    input {
      width: 100%;
    }
    svg {
      position: absolute;
      right: 10px;
      top: 15px;
      z-index: 10;
      cursor: pointer;
      width: 26px;
      height: 19px;
    }
  }

  .disabled {
    border: 1px solid rgba(2, 40, 94, 0.1);
    background: rgba(163, 179, 201, 0.3) !important;
    input {
      background: rgba(163, 179, 201, 0.3) !important;
    }
  }

  .error {
    color: red;
    font-size: 14px;
  }
}
