.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    color: #02285e;
  }
}
