.calendarView {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .back-forward-buttons {
      display: flex;
      gap: 0rem;
      align-items: center;

      .current-month {
        /* June 2024 */

        width: 180px;
        height: 24px;
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.04em;

        color: #02285e;
      }

      .prev-button,
      .next-button {
        cursor: pointer;
        border-radius: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 36px;
        height: 36px;

        background: #02285e;
        border-radius: 5px;

        &:disabled {
          background-color: #d6d6d6;
          cursor: not-allowed;
        }
      }
    }

    .filters {
      display: flex;
      gap: 1rem;
      align-items: center;

      .switch-filter {
        display: flex;
        gap: 10px;
        align-items: center;
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          /* identical to box height, or 162% */
          letter-spacing: -0.02em;

          /* Primary/Base/Black */
          color: #000000;
        }
      }

      .group-filter {
        display: flex;
        display: flex;
        gap: 10px;
        align-items: center;

        .label {
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: -0.268333px;
          color: #02285e;
        }
      }
    }
  }
}
