.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 0rem;

  .heading-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: -10px;
    .heading {
      font-weight: 700;
      font-size: 24px;
      line-height: 20px;
      color: #02285e;
    }
  }

  .channels-container {
    display: flex;
    gap: 1rem;
  }
}

.header-text {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;

  button {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 310 !important;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
  }
}

.channel-card {
  display: flex;
  flex-direction: column;
  gap: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 3rem;

  background: #ffffff;
  border-radius: 10px;

  svg {
    margin-bottom: 10px;
  }

  button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    min-width: 270px;
    color: #ffffff;
  }
}

.disabled-channel-card {
  button {
    background-color: #778ba8 !important;
  }
}

.connect-link {
  display: flex;
  width: 280px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: var(--eriko-blue, #02285e);
}
