.container {
  border-radius: 8px;
  max-width: fit-content !important;
  top: 2%;
}

.content {
  min-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  img{
    max-width: 700px;
    height: 500px;
  }

  textarea {
    min-width: 700px;
    padding: 1rem;
  }

  footer {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 1rem;
    .action-btn {
      min-width: 100px;
      align-self: flex-end;
      height: 40px;
    }
  }
}
