.prompt-container {
  .header-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    text-transform: capitalize;
    color: #02285e;
  }

  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  .template-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .submit-button {
    min-width: 70px;
    align-self: flex-end;
  }
}



.marketing-brief-form-card {
  box-shadow: none !important;
}
