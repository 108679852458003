@use "../../../../styles/mixins.scss" as m;

.container {
  display: flex;
  align-items: flex-start;

  gap: 0.5rem;
  .content {
    // max-width: 611px;
    max-width: 100%;
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
   
  }

  .ninja-icon {
    max-height: 50px;
    flex-shrink: 0;
    @include m.sm {
      max-height: 30px;
    }
  }

  .user-icon {
    max-height: 50px;
    flex-shrink: 0;
  }
}
