.card {
  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  span[aria-live="polite"] {
    position: absolute;
    display: block;
    height: 100% !important;
    width: 100% !important;
    top: -4px;
    left: 0px;
  }
}

.pin-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    path {
      fill: #eb2701;
    }
  }
}

.ellipses-card {
  background: #ffffff;
  border: 1px solid rgba(235, 39, 1, 0.5);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 1rem;

  .ellipses {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.skeleton {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100% !important;
}
