.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #02285e;
  }

  .body-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;

    color: #000000;

  

    ul {
      list-style-type: disc;
      margin-top: 5px;
      margin-left: 20px;

      li {
        list-style: disc;
        // padding-bottom: 10px;
        font-size: 16px;
        font-family: "Inter", sans-serif;
      }
    }
  }
}
