.container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 400px;
  max-width: 90%;
  margin: auto;
  z-index: 1000;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
      width: 100%;
      padding: 10px;
      // border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 14px;
      outline: none;
      transition: border 0.3s ease;
    }

    input:focus {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }

    label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
      color: #333;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    min-width: fit-content;
  }
}
