.container {
  background: #ffffff;
  border-radius: 0px;
}

.content {
  min-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close-icon {
      cursor: pointer;
    }

    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      color: #02285e;
    }

    .success-container {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;

      border-radius: 4px;
      padding: 10px;
      margin-top: 10px;
    }
  }

  .subjective-text {
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  .action-btn-container {
    display: flex;
    gap: 1rem;
    align-self: flex-end;
    .submit-btn {
      min-width: 100px;
      align-self: flex-end;
    }

    .cancel-btn {
      min-width: 100px;
      align-self: flex-end;
      border-radius: 10px;
      background: var(--Eriko-Red, #eb2701);
    }
  }
}
