.header-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;
}

.filter-container {
  padding-top: 2rem;
  display: flex;
  gap: 2rem;

  .delete-btn {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

.content {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 30px;
  margin-right: 20px;
  gap: 20px;

  .accordion {
    flex-grow: 1;
    flex-basis: calc(33.3% - 20px);
    height: 60px;
    background-color: #ffffff;
    overflow: hidden;
    transition: height 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

    .accordionHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      height: 60px;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    .accordionDownArrow {
      font-size: xx-large;
      color: #02285e;
    }

    .accordionTitle {
      color: #eb2701;
      font-size: xx-large;
    }

    .accordionContent {
      color: #02285e;
      padding: 20px;
    }
  }
}

.formGroup {
  background: var(--white, #fff);
  margin-bottom: 16px;

  .formLabel {
    display: block;
    margin-bottom: 8px;

    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #02285e;
  }

  .formInput {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid var(--light-grey, #ccc);
    border-radius: 4px;
    box-sizing: border-box;
    resize: none;

    background: #ffffff;
    border: 1px solid #eb2701;
    border-radius: 5px;

    &:focus {
      border-color: var(--Eriko-Red, #eb2701);
      outline: none;
    }
  }
}

.formSubmitButton {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;

  button {
    min-width: 95px;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: var(--Eriko-Red, #02285e);
    color: var(--white, #fff);
    font-size: 16px;
    cursor: pointer;
  }
}

.styleGuideLogoTitle {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #02285e;
}

.styleGuideImageSvgSection {
  display: flex;
  gap: 20px;
}

.styleGuideImageSvg {
  border-radius: 2px;
  border: #eb2701;
  // height: 100px;
  width: 100px;
  margin-top: 10px;
  background-color: #eceef1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styleGuideImageContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .directive-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }
}

.file-uploader-container {
  display: flex;
  align-items: center;
  padding: 1rem;

  background: #fafafa;
  border-radius: 5px;
}

.styleGuideFileInput {
  width: 100%;
  height: 40px;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.styleGuideFileInputLabel {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;

  background: #ffffff;
  border: 1px solid #eb2701;
  border-radius: 5px;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #eb2701;
}

.styleGuideChooseFile {
  display: flex;
  justify-content: "center";
  align-items: "center";
  padding-left: 20px;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #3c3c3c;
}

.color-box-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  .color-box {
    min-width: 100px !important;
    height: 100px !important;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}

.branding-colors {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem !important;
  width: 100%;

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #02285e;
  }
}
.color-action-btn{
  min-width: 70px;
  align-self: flex-end;
}
