.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;

  background: #ffffff;
  box-shadow: 0px 18px 105px rgba(18, 18, 18, 0.03),
    0px 7.51997px 43.8665px rgba(18, 18, 18, 0.0215656),
    0px 4.02054px 23.4531px rgba(18, 18, 18, 0.0178832),
    0px 2.25388px 13.1476px rgba(18, 18, 18, 0.015),
    0px 1.19702px 6.98261px rgba(18, 18, 18, 0.0121168),
    0px 0.498106px 2.90562px rgba(18, 18, 18, 0.00843437);
  border-radius: 10px;

  .input {
    flex: 1;
    width: max-content;
    border: none;
    outline: none;
    border-radius: 10px;

    font-family: "Inter", sans-serif;
    font-size: 1rem;

    color: var(--black, #121212);
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;

    &::placeholder {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: rgba(0, 0, 0, 0.5);
    }
  }

  .icon {
    cursor: pointer;
  }
}
