.cmo-view-layout {
  position: relative;
  max-height: 100%;
}

.container {
  height: 100%;

  .side-bar {
    position: fixed;
    top: 0px;
    left: 0px;
  }

  .header {
    width: 100%;
    padding-left: 100px;
    height: 136px;
    position: absolute;
    top: 0px;
  }

  .content {
    width: 100%;
    padding-left: 100px;
    padding-right: 20px;
    position: absolute;
    top: 137px;
  }
}
