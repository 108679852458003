
.loader {
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
  }
  
  @keyframes l5 {
    0% {
      box-shadow: 20px 0 #eb2701, -20px 0 rgba(235, 39, 1, 0.6);
      background: rgba(235, 39, 1, 0.3);
    }
    33% {
      box-shadow: 20px 0 #eb2701, -20px 0 rgba(235, 39, 1, 0.6);
      background: rgba(235, 39, 1, 0.6);
    }
    66% {
      box-shadow: 20px 0 rgba(235, 39, 1, 0.6), -20px 0 #eb2701;
      background: rgba(235, 39, 1, 0.3);
    }
    100% {
      box-shadow: 20px 0 rgba(235, 39, 1, 0.3), -20px 0 #eb2701;
      background: #eb2701;
    }
  }
  