.container {
  background: #eceef1 !important;
  border: 1px solid #02285e;
  border-radius: 5px;
}

.content {
  min-width: 400px;
  display: flex;
  gap: 10px;
  padding: 10px;

  input {
    flex: 1;
    border: none;
    background: none;

    &::placeholder {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: rgba(0, 0, 0, 0.5);
    }

    &:focus {
      outline: none;
    }
  }
}

.modal {
  max-width: 578px;
  position: absolute;
  // top: 10%;
  // left: 35%;
  z-index: 300;
  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
