.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #02285e;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .content-heading {
      width: 100%;
      background: #778ba8;
      border: 1px solid rgba(119, 139, 168, 0.5);
      padding: 1rem;

      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      color: #ffffff;
    }

    .content {
      padding: 1rem;
      width: 100%;
      background: rgba(200, 95, 95, 0.002);
      border: 1px solid rgba(119, 139, 168, 0.5);

      ol {
        margin: 0;
        padding: 0rem 1rem;
        li {
          margin-bottom: 20px;
          margin-left: -20px;
        }
      }

      ul {
        list-style-type: disc;
        margin-top: 5px;
        margin-left: 20px;

        li {
          list-style: disc;
          padding-bottom: 10px;
          font-size: 16px;
          font-family: "Inter", sans-serif;
        }
      }

      .highlight {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
      }

      .ul-with-heading {
        ul {
          margin-left: 50px;
        }
      }
    }
  }
}

.sub-heading-text {
  color: #eb2701;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;
}

.product-demo-invitations-content {
  p {
    span {

      font-style: normal;
      font-weight: 400 !important;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      color: #eb2701;
    }
  }
}
