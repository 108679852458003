.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 200px;
}

.overview-card {
  padding: 10px;

  .title {
    font-size: 1rem;
    font-weight: 700 !important;
    color: #02285e;
  }

  .description {
    padding: 1rem;
  }
}

.node-details-wrapper {
  display: flex;
  flex-direction: column;

  .details {
    display: flex;
    flex-direction: column;
    // padding: 1rem;
    .text {
      margin: 0rem 1rem;
      line-height: 23px;
      background-color: white;
      padding-left: 10px;
      padding-right: 10px;
      &:first-child{
        margin-top: 1rem !important;
        padding-top: 1rem;

      }

      &:last-child{
        margin-bottom: 1rem;
        padding-bottom: 1rem;
      }
      span {
        line-height: 16px !important;
        color: #02285e;
        font-size: 16px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 24px; /* 150% */
        text-transform: capitalize;
      }
    }
  }
}

.node-icon {
  display: flex;
  padding: 3px 10px;
  align-items: center;
  gap: 5px;
  border-radius: 10px 10px 0px 0px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);

  width: fit-content;

  p {
    color: #fff;

    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
}

.entry-node-icon {
  background: #38b05a;
}
.entry-node-details {
  background: rgba(56, 176, 90, 0.05);
}

.action-node-icon {
  background: #18a2f2;
}

.action-node-details {
  background: rgba(24, 162, 242, 0.05);
}

.email-node-icon {
  background: #024a9e; /* A slightly lighter, rich blue */

  svg {
    path {
      fill: #fff !important;
    }
  }
}

.email-node-details {
  background: rgba(2, 42, 94, 0.05); /* A very light transparent blue */
}

.diamond-node-icon {
  background: #ffa600;
}

.diamond-node-details {
  background: rgba(255, 166, 0, 0.05);
}

.delay-node-icon {
  background: #778ba8;
}

.delay-node-details {
  background: rgba(119, 139, 168, 0.05);
}

.exit-node-icon {
  background: #eb2701;
}

.exit-node-details {
  background: rgba(235, 39, 1, 0.05);
}

.check-node-icon {
  background: #eb6301;
}

.check-node-details {
  background: rgba(235, 99, 1, 0.05);
}

.closure-header {
  background: none !important;
  padding: 7px 3px !important;

  text-transform: lowercase !important;
  border-bottom: 2px solid #eb2701 !important;
  p {
    text-transform: capitalize !important;
    font-weight: 450 !important;
    font-size: 20px;
    color: #02285e;
  }
}

.closure-content {
}

.open-closure-header {
  p {
    color: #02285e;
    text-transform: capitalize !important;
  }
  svg {
    path {
      fill: #02285e;
    }
  }
}

.groups-container {
  display: grid;
  padding: 1rem 5px;
  grid-template-columns: repeat(3, minmax(30%, 1fr));
  gap: 1rem;

  .group-card {
    // padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .group {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.build-journey-button {
  max-width: 120px !important;
  width: 120px !important;
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  // margin-bottom: 100px;
}

.stage-heading {
  color: #02285e;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-transform: capitalize;
}
