@use "../../styles/colors.scss" as c;
@use "../../styles/mixins.scss" as m;
@use "../../styles/variables.scss" as v;

.content-text {
  font-size: 1rem;
}

.container {
  align-self: flex-end;
  position: relative;
  width: 100%;
  height: 100%;

  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  gap: 1.5rem;
  overflow: auto;
  max-height: 100%;
  flex: 1;

  .chat-container {
    position: absolute;
    display: flex;
    top: 12px;
    right: 0px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 2rem;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .chat {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;
    flex: 1;
    overflow: auto;
    .left {
      align-self: flex-start;

      p {
        font-size: 1rem;
      }
      svg {
        height: 40px;
        width: 40px;
      }
    }

    .right {
      align-self: flex-end;
      p {
        font-size: 1rem;
      }
      svg {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.msg-content {
  padding: 0.5rem 1rem;
}


.input-container {
  padding: 5px 15px;
  input {
    font-size: 1rem;
  }

  svg {
    height: 20px;
    width: 20px;
  }
}

.ninja-icon {
  max-height: 50px;
  flex-shrink: 0;
  @include m.sm {
    max-height: 30px;
  }
}

.user-icon {
  max-height: 50px;
  flex-shrink: 0;
}

.chat-message {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 70% !important;
}

.right {
  margin-right: 0px;
  flex-direction: row-reverse;
  p {
    line-break: anywhere;
    background-color: #f2f2f2;
    padding: 5px 8px;
    border-radius: 10px;
    
  }
}

.left {

  p {
    padding: 5px 8px;
    border-radius: 10px;
    background-color: #f2f2f2;
  }
}
