.container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;

  .body {
    display: flex;
    gap: 1px;
    flex-direction: row;
    flex: 1;
    width: 100%;
    overflow: hidden;

    .vertical-tabs {
      width: 200px;
    }

    .model-response {
      overflow: auto;
      flex: 1;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 10px;
      box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
      border-radius: 10px 10px 0px 0px;
      display: flex;
      flex-direction: column;
      position: relative;
      background: #fafafa;
      overflow-y: scroll;

      .title {
        background: #02285e;
        width: 100%;
        padding: 10px 16px;

        position: sticky;
        // z-index: 1 !important;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        text-transform: uppercase;
        border-radius: 10px 10px 0px 0px;
        color: #ffffff;
        // position: sticky;
        // top: 0px;
      }

      .content {
        min-height: fit-content !important;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        margin: 10px;

        .plan {
          min-height: 300px;
          padding: 0px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }
    }
  }

  .content-expanded {
    .vertical-tabs {
      min-width: 30% !important;
      width: 30% !important;
      max-width: 30% !important;
    }

    .model-response {
      flex: 1;
    }
  }

  .content-collapsed {
    .vertical-tabs {
      max-width: 50px;
    }

    .model-response {
      flex: 1;
    }
  }
}

.campaign-query-chat {
  height: 100%;
  padding: 0rem 10px;
  padding-bottom: 10px;
}

.consideration-stage {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 1rem 0rem;

  .select-menu {
    min-width: fit-content;
    .select-button {
      border-radius: 5px;
      min-width: fit-content !important;
      max-width: 400px !important;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    z-index: 1000;
  }

  .account-details {
    color: #eb2701;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
  }
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

.disclosure {
  border-radius: 5px;
  background: #fff;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05) !important;
}

.closure-header {
  background: none !important;
  padding: 7px 3px !important;

  svg {
    path {
      fill: #eb2701 !important;
    }
  }
  p {
    color: #02285e;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-transform: capitalize !important;
  }
}

.closure-content {
}

.open-closure-header {
  p {
    color: #02285e;
    text-transform: capitalize !important;
  }
  svg {
    path {
      fill: #eb2701;
    }
  }
}

.generate-action-plan {
  width: fit-content;
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.confidence-score-heading {
  color: #02285e;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.metric-container {
  display: flex;
  gap: 1rem;

  .metric {
    width: 100%;

    border-radius: 10px;
    border: 1px solid rgba(163, 179, 201, 0.2);
    background: #fff;
    .icon-wrapper {
      border-radius: 15px;
      background: #eb2701 !important;
      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }
}
