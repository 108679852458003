.container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // width: 100%;

  // margin-bottom: 100px;

  .body {
    display: flex;
    gap: 1px;
    flex-direction: row;
    flex: 1;
    width: 100%;
    overflow: hidden;

    .vertical-tabs {
      width: 200px;
    }

    .model-response {
      flex: 1;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 10px;
      box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
      border-radius: 10px 10px 0px 0px;
      display: flex;
      flex-direction: column;
      position: relative;
      background: #fafafa;
      overflow: auto;

      .title {
        background: #02285e;
        width: 100%;
        padding: 10px 16px;

        position: sticky;
        // z-index: 1 !important;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        text-transform: uppercase;
        border-radius: 10px 10px 0px 0px;
        color: #ffffff;
        // position: sticky;
        // top: 0px;
      }

      .content {
        background: #fff;
        margin: 1rem;
        padding: 1rem;
        min-height: fit-content !important;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 200px;

        .sub-title {
          color: #02285e;
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
        }

        .filters {
          display: flex;
          gap: 1rem;
          align-items: center;

          .select-menu {
            min-width: fit-content;
            .select-button {
              height: 40px;
              border-radius: 5px;
              // min-width: fit-content !important;
              max-width: 400px !important;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .select-button {
            svg {
              min-width: 35px;
            }

            .selected-options {
              overflow: hidden !important;
            }
          }

          .create-campaign-button {
            color: #fff;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            height: 40px;
            line-height: 24px;
          }
        }

        .metrics {
          display: flex;
          gap: 1rem;
        }

        .graph-container {
          display: flex;
          gap: 1rem;
        }
      }
    }
  }

  .content-expanded {
    .vertical-tabs {
      min-width: 30% !important;
      width: 30% !important;
      max-width: 30% !important;
    }

    .model-response {
      flex: 1;
    }
  }

  .content-collapsed {
    .vertical-tabs {
      max-width: 50px;
    }

    .model-response {
      flex: 1;
    }
  }
}

.campaign-query-chat {
  height: 100%;
  padding: 0rem 10px;
  padding-bottom: 10px;
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 1rem;
  background-color: #ffffff;
  margin: 10px;
}

.build-journey-button {
  max-width: 120px !important;
  width: 120px !important;
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: 15px;
  // margin-bottom: 100px;
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 25px;
}
